<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">إضافة لقاح</h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form
                @submit.prevent="addVaccine"
                class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4  sm:p-6 shadow border dark:border-slate-800  sm:rounded-md sm:overflow-hidden"
            >
                <div class="grid md:grid-cols-4 gap-8">

                    <div class="input" :class="{ invalid: $v.vaccine.description.$error }">
                        <label for="vaccine_description" class="block text-sm font-medium ">
                            الفئة
                        </label>
                        <input
                            type="text"
                            id="vaccine_description"
                            placeholder="أكتب الفئة."
                            v-model="vaccine.description"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.vaccine.description.$touch()"
                        />
                        <p v-if="!$v.vaccine.description.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن تحتوي الفئة على
                            {{ $v.vaccine.description.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input" :class="{ invalid: $v.vaccine.order.$error }">
                        <label for="vaccine_order" class="block text-sm font-medium ">
                            الترتيب في الجدول
                        </label>
                        <input
                            type="number"
                            id="vaccine_order"
                            placeholder="أكتب الترتيب في الجدول."
                            v-model="vaccine.order"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.vaccine.order.$touch()"
                        />
                     
                    </div>

                    <div class="input" :class="{ invalid: $v.vaccine.type.$error }">
                        <fieldset>
                            <legend for="administrator_gender" class="block text-sm font-medium ">فئة القاح</legend>
                            <div class="mt-4 flex">
                                <div class="flex items-center w-28">
                                    <input 
                                        id="Mandatory" 
                                        name="administrator_gender" 
                                        v-model="vaccine.type" 
                                        value="1" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.vaccine.type.$touch()"
                                    />
                                    <label for="Mandatory" class="mr-2 block text-sm font-medium ">
                                        الإجباري
                                    </label>
                                </div>
                                <div class="flex items-center w-28">
                                    <input 
                                        id="Adult" 
                                        name="administrator_gender" 
                                        v-model="vaccine.type" 
                                        value="2" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.vaccine.type.$touch()"
                                    />
                                    <label for="Adult" class="mr-2 block text-sm font-medium ">
                                        كبار
                                    </label>
                                </div>

                                <div class="flex items-center w-28">
                                    <input 
                                        id="travelers" 
                                        name="administrator_gender" 
                                        v-model="vaccine.type" 
                                        value="3" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.vaccine.type.$touch()"
                                    />
                                    <label for="travelers" class="mr-2 block text-sm font-medium ">
                                        مسافرين
                                    </label>
                                </div>


                                <div class="flex items-center w-28">
                                    <input 
                                        id="Campaigns" 
                                        name="administrator_gender" 
                                        v-model="vaccine.type" 
                                        value="4" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.vaccine.type.$touch()"
                                    />
                                    <label for="Campaigns" class="mr-2 block text-sm font-medium ">
                                        حملات
                                    </label>
                                </div>

                            </div>
                        </fieldset>
                    </div>
       
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            :disabled="$v.$invalid"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                            إضافة
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import errormessage from "@/components/ErrorMessage"
import { required, minLength } from "vuelidate/lib/validators";
import AgeCategoriesService from "@/services/AgeCategoriesService";



export default {
    components: {
        errormessage
    },
    data() {
        return {
            errors: null,
         
            vaccine: {
                description : '',
                order : '',
                type : '',
            },
            // loader: "dots",
        };
    },
    created() {
       
    },
    validations: {
        vaccine: {
            description: {
                required,
                minLength: minLength(2),
            },
            order: {
                required,
            },
            type: {
                required,
            },
        },
    },
    methods: {
        addVaccine() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                description : this.vaccine.description,
                order : this.vaccine.order,
                type : this.vaccine.type,
            }

            AgeCategoriesService.createAgeCategories(data)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.$router.push({ name: "AgeCategories" });
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.errors.name,
                    })
                });
        },
    },
};
</script>

<style scoped>
.input.invalid label {
    color: red;
}
.input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
}

[type="submit"],
button {
    cursor: inherit;
    color: inherit;
}
</style>
