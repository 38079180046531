<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">إضافة بلدية</h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form
                @submit.prevent="addMunicipal"
                class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4  sm:p-6 shadow border dark:border-slate-800  sm:rounded-md sm:overflow-hidden"
            >
                <div class="grid md:grid-cols-4 gap-8">

                    <div class="input" :class="{ invalid: $v.municipal.name.$error }">
                        <label for="municipal_name" class="block text-sm font-medium ">
                            الاسم
                        </label>
                        <input
                            type="text"
                            id="municipal_name"
                            placeholder="أكتب الاسم."
                            v-model="municipal.name"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.municipal.name.$touch()"
                        />
                        <p v-if="!$v.municipal.name.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.municipal.name.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input" :class="{ invalid: $v.municipal.address.$error }">
                        <label for="municipal_address" class="block text-sm font-medium ">
                            العنوان
                        </label>
                        <input
                            type="text"
                            id="municipal_address"
                            placeholder="أكتب العنوان."
                            v-model="municipal.address"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.municipal.address.$touch()"
                        />
                        <p v-if="!$v.municipal.address.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.municipal.address.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input" >
                        <label for="municipal_longitude" class="block text-sm font-medium ">
                            خط الطول 
                        </label>
                        <input
                            type="text"
                            id="municipal_longitude"
                            placeholder="أكتب خط الطول ."
                            v-model="municipal.longitude"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        
                    </div>

                    <div class="input" >
                        <label for="municipal_latitude" class="block text-sm font-medium ">
                            خط العرض
                        </label>
                        <input
                            type="text"
                            id="municipal_latitude"
                            placeholder="أكتب خط العرض."
                            v-model="municipal.latitude"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        
                    </div>

                    <div class="input" :class="{ invalid: $v.municipal.storage_address.$error }">
                        <label for="municipal_storage_address" class="block text-sm font-medium ">
                            عنوان المخزن
                        </label>
                        <input
                            type="text"
                            id="municipal_storage_address"
                            placeholder="أكتب عنوان المخزن."
                            v-model="municipal.storage_address"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.municipal.storage_address.$touch()"
                        />
                        <p v-if="!$v.municipal.storage_address.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.municipal.storage_address.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input" >
                        <label for="municipal_storage_longitude" class="block text-sm font-medium ">
                            خط طول المخزن
                        </label>
                        <input
                            type="text"
                            id="municipal_storage_longitude"
                            placeholder="أكتب خط طول المخزن."
                            v-model="municipal.storage_longitude"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      
                    </div>

                    <div class="input" >
                        <label for="municipal_storage_latitude" class="block text-sm font-medium ">
                            خط عرض المخزن
                        </label>
                        <input
                            type="text"
                            id="municipal_storage_latitude"
                            placeholder="أكتب خط عرض المخزن."
                            v-model="municipal.storage_latitude"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>

                    <div class="input" :class="{ invalid: $v.municipal.medical_suppliesid.$error }">
                        <label for="medical_suppliesid" class="block text-sm font-medium ">
                            اختر الامداد الطبي
                        </label>
                        <select
                            id="medical_suppliesid"
                            name="medical_suppliesid"
                            v-model="municipal.medical_suppliesid"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>الامداد الطبي</option>
                            <option
                                v-for="medical_supply in medical_supplies_data"
                                :key="medical_supply.id"
                                v-bind:value="medical_supply.id"
                            >
                                {{ medical_supply.name }}
                            </option>

                        </select>
                    </div>

                    <div class="input" >
                        <label for="administrator_id" class="block text-sm font-medium ">
                            اختر المسؤول 
                        </label>
                        <select
                            id="administrator_id"
                            name="administrator_id"
                            v-model="prepar_administrator_id"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>المسؤول</option>
                            <option
                                v-for="administrator in administrators_data"
                                :key="administrator.id"
                                v-bind:value="administrator.id"
                            >
                                {{ administrator.full_name }}
                            </option>

                        </select>
                    </div>
       
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            :disabled="$v.$invalid"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                            إضافة
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import errormessage from "@/components/ErrorMessage"
import { required, minLength } from "vuelidate/lib/validators";
import MunicipalsService from "@/services/MunicipalsService";

import MedicalSuppliesService from "@/services/MedicalSuppliesService";
import AdministratorsService from "@/services/AdministratorsService";

export default {
    components: {
        errormessage
    },
    data() {
        return {
            errors: null,
            medical_supplies_data: {},
            administrators_data:{},
            municipal: {
                name : '',
                address : '',
                longitude : '',
                latitude : '',
                storage_address : '',
                storage_longitude : '',
                storage_latitude : '',
                medical_suppliesid: '',
                administrator_id : '',
                role_id : '',
            },
            prepar_administrator_id : '',
            // loader: "dots",
        };
    },

    watch: {
        prepar_administrator_id: function() {
            
            let administrator = this.administrators_data.find(
                (element) => element.id == this.prepar_administrator_id
            );

            this.municipal.administrator_id = administrator.id
            this.municipal.role_id = administrator.role_id

        },
    },

    created() {
        this.MedicalSupplies();
        this.getAdministrators();
    },
    validations: {
        municipal: {
            name: {
                required,
                minLength: minLength(3),
            },
            address: {
                required,
                minLength: minLength(3),
            },
            storage_address: {
                required,
                minLength: minLength(3),
            },
            medical_suppliesid: {
                required,
            },
            administrator_id: {
                required,
            },
        },
    },
    methods: {

        getAdministrators() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            AdministratorsService.getAdministratorsByRole(2)
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.administrators_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },
        
        MedicalSupplies() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            MedicalSuppliesService.getMedicalSuppliesWithoutPagination()
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.medical_supplies_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        addMunicipal() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                municipal:{
                    name : this.municipal.name,
                    address : this.municipal.address,
                    longitude : Number(this.municipal.longitude),
                    latitude : Number(this.municipal.latitude),
                    storage_address : this.municipal.storage_address,
                    storage_longitude : Number(this.municipal.storage_longitude),
                    storage_latitude : Number(this.municipal.storage_latitude),
                    medical_suppliesid : this.municipal.medical_suppliesid,
                },
                administrator:{
                    administrator_id: this.municipal.administrator_id,
                    role_id: this.municipal.role_id,
                    type: 2
                }
            }

            MunicipalsService.createMunicipal(data)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.$router.push({ name: "municipals" });
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.errors.name,
                    })
                });
        },
    },
};
</script>

<style scoped>
.input.invalid label {
    color: red;
}
.input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
}

[type="submit"],
button {
    cursor: inherit;
    color: inherit;
}
</style>
