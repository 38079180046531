<template>
  <div dir="rtl">
    <div
      v-if="$route.meta.DashboardLayout"
      class=""
    >
      <LayoutContent />
    </div>
    <div v-else>
      <LayoutBlank />
    </div>
  </div>
</template>

<script>
import LayoutBlank from "./layouts/Blank.vue";
import LayoutContent from "./layouts/Content.vue";

export default {
  name: "App",

  components: {
    LayoutBlank,
    LayoutContent,
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>
