import * as API from "./API";

import store from "../store";

export default {
    getNationalitiesWithoutPagination() {
        return API.apiClient.get(`/api/Nationality/GetAll`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getNationalities(page_number, page_size) {
        return API.apiClient.get(`/api/Nationality/GetALlNationality?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getNationality(id) {
        return API.apiClient.get(`/api/Nationality/GetById?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createNationality(nationality) {
        return API.apiClient.post(`/api/Nationality/Add`, nationality, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteNationality(id) {
        return API.apiClient.delete(`/api/Nationality/Delete?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateNationality(nationality) {
        return API.apiClient.put(`/api/Nationality/Update`, nationality, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};