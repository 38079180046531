<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">إضافة </h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form
                @submit.prevent="addMedicalSupply"
                class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4  sm:p-6 shadow border dark:border-slate-800  sm:rounded-md sm:overflow-hidden"
            >
                <div class="grid md:grid-cols-4 gap-8">

                    <div class="input" :class="{ invalid: $v.citizen.nationalityid.$error }">
                        <label for="nationalityid" class="block text-sm font-medium ">
                            اختر الجنسية 
                        </label>
                        <select
                            id="nationalityid"
                            name="nationalityid"
                            v-model="citizen.nationalityid"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>الجنسيات</option>
                            <option
                                v-for="nationality in nationalities_data"
                                :key="nationality.id"
                                v-bind:value="nationality.id"
                            >
                                {{ nationality.name_ar }}
                            </option>

                        </select>
                    </div>

                    <!-- <div class="input" :class="{ invalid: $v.municipal_id.$error }">
                        <label for="municipal_id" class="block text-sm font-medium ">
                            اختر البلدية 
                        </label>
                        <select
                            id="municipal_id"
                            name="municipal_id"
                            v-model="municipal_id"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>البلدية</option>
                            <option
                                v-for="municipal in municipals_data"
                                :key="municipal.id"
                                v-bind:value="municipal.id"
                            >
                                {{ municipal.name }}
                            </option>

                        </select>
                    </div> -->

                    <div class="input" :class="{ invalid: $v.citizen.health_Centerid.$error }">
                        <label for="health_Centerid" class="block text-sm font-medium ">
                            اختر المركز الصحي 
                        </label>
                        <select
                            id="health_Centerid"
                            name="health_Centerid"
                            v-model="citizen.health_Centerid"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>المراكز</option>
                            <option
                                v-for="vaccination_center in vaccination_centers_data"
                                :key="vaccination_center.id"
                                v-bind:value="vaccination_center.id"
                            >
                                {{ vaccination_center.name }}
                            </option>

                        </select>
                    </div>

                    <div class="input" :class="{ invalid: $v.citizen.name.$error }">
                        <label for="citizen_name" class="block text-sm font-medium ">
                            الاسم
                        </label>
                        <input
                            type="text"
                            id="citizen_name"
                            placeholder="أكتب الاسم."
                            v-model="citizen.name"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.citizen.name.$touch()"
                        />
                        <p v-if="!$v.citizen.name.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.citizen.name.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input" >
                        <label for="phone_number" class="block text-sm font-medium ">
                            رقم الهاتف
                        </label>
                        <input
                            type="tel"
                            id="phone_number"
                            placeholder="أكتب رقم الهاتف."
                            v-model="citizen.phone_number"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>

                    <div v-if="citizen.nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'" class="input">
                        <label for="citizen_national_number" class="block text-sm font-medium ">
                            الرقم الوطني
                        </label>
                        <input
                            type="number"
                            id="citizen_national_number"
                            placeholder="أكتب الرقم الوطني."
                            v-model="citizen.national_number"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>

                    <div v-if="citizen.nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"  class="input" >
                        <label for="citizen_family_paper_number" class="block text-sm font-medium ">
                            رقم ورقة العائلة
                        </label>
                        <input
                            type="number"
                            id="citizen_family_paper_number"
                            placeholder="أكتب رقم ورقة العائلة."
                            v-model="citizen.family_paper_number"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                       
                    </div>

                    

                    <div 
                        v-if="citizen.nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"  
                        class="input"
                        :class="{ invalid: $v.citizen.registration_number.$error }"
                    >
                        <label for="citizen_registration_number" class="block text-sm font-medium ">
                            رقم القيد
                        </label>
                        <input
                            type="number"
                            id="citizen_registration_number"
                            placeholder="أكتب الرقم."
                            v-model="citizen.registration_number"
                            @blur="$v.citizen.registration_number.$touch()"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                       
                    </div>

                    <div v-if="citizen.nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'" class="input" >
                        <label for="citizen_brochure_number" class="block text-sm font-medium ">
                            رقم الكتيب
                        </label>
                        <input
                            type="number"
                            id="citizen_brochure_number"
                            placeholder="أكتب الاسم."
                            v-model="citizen.brochure_number"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        
                    </div>

                    <div class="input" :class="{ invalid: $v.citizen.date_of_birth.$error }">
                        <label for="citizen_date_of_birth" class="block text-sm font-medium ">
                           تاريخ الميلاد
                        </label>
                        <input
                            type="date"
                            id="citizen_date_of_birth"
                            placeholder="أكتب الاسم."
                            v-model="citizen.date_of_birth"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.citizen.date_of_birth.$touch()"
                        />
                        
                    </div>

                    <div class="input" >
                        <label for="citizen_system_number" class="block text-sm font-medium ">
                            رقم التسجيل
                        </label>
                        <input
                            type="text"
                            id="citizen_system_number"
                            placeholder="أكتب رقم التسجيل."
                            v-model="citizen.system_number"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        
                    </div>

                    <div class="input" :class="{ invalid: $v.citizen.passport_number.$error }">
                        <label for="citizen_passport_number" class="block text-sm font-medium ">
                            رقم جواز السفر
                        </label>
                        <input
                            type="text"
                            id="citizen_passport_number"
                            placeholder="أكتب الاسم."
                            v-model="citizen.passport_number"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.citizen.passport_number.$touch()"
                        />
                        <!-- <p v-if="!$v.citizen.passport_number.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.citizen.passport_number.$params.minLength.min }} أحرف على
                            الأقل.
                        </p> -->
                    </div>

                    <div class="input" :class="{ invalid: $v.relative_relation.$error }"> 
                        <fieldset>
                            <legend for="citizen_relative_relation" class="block text-sm font-medium ">
                                الصفة
                            </legend>
                            <div class="mt-4 flex">
                                <div class="flex items-center w-16">
                                    <input 
                                        id="father" 
                                        name="citizen_relative_relation" 
                                        v-model="relative_relation" 
                                        value="1" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.relative_relation.$touch()"
                                    />
                                    <label for="father" class="mr-2 block text-sm font-medium ">
                                        اب
                                    </label>
                                </div>
                                <div class="flex items-center w-16">
                                    <input 
                                        id="mother" 
                                        name="citizen_relative_relation" 
                                        v-model="relative_relation" 
                                        value="2" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.relative_relation.$touch()"
                                    />
                                    <label for="mother" class="mr-2 block text-sm font-medium ">
                                        ام
                                    </label>
                                </div>
                                <div class="flex items-center w-16">
                                    <input 
                                        id="Son" 
                                        name="citizen_relative_relation" 
                                        v-model="relative_relation" 
                                        value="3" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.relative_relation.$touch()"
                                    />
                                    <label for="Son" class="mr-2 block text-sm font-medium ">
                                        ابن
                                    </label>
                                </div>
                                <div class="flex items-center w-16">
                                    <input 
                                        id="daughter" 
                                        name="citizen_relative_relation" 
                                        v-model="relative_relation" 
                                        value="4" 
                                        type="radio" 
                                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" 
                                        @blur="$v.relative_relation.$touch()"
                                    />
                                    <label for="daughter" class="mr-2 block text-sm font-medium ">
                                        ابنة
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div 
                        v-if="citizen.nationalityid != '2378e80b-3358-427e-8544-9f776ea7e302' && citizen.relative_relation != 1"  
                        class="input" 
                        :class="{ invalid: $v.citizen.guardian_number.$error }"
                    >
                        <label for="citizen_guardian_number" class="block text-sm font-medium ">
                            رابط العائلة
                            
                        </label>
                        <input
                            type="text"
                            id="citizen_guardian_number"
                            placeholder="أكتب الرقم."
                            v-model="citizen.guardian_number"
                            @blur="$v.citizen.guardian_number.$touch()"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        <span class="text-xs">
                            رقم القيد في حالة ولي الامر ليبي الجنسية
                            أو 
                            رقم جواز سفر ولي امر العائلة في حالة ان ولي الامر ليس ليبي الجنسية
                        </span>
                    </div>
       
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            :disabled="$v.$invalid"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                            إضافة
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import errormessage from "@/components/ErrorMessage"
import { required, minLength } from "vuelidate/lib/validators";
import CitizensService from "@/services/CitizensService";

import NationalitiesService from "@/services/NationalitiesService";
import VaccinationCentersService from "@/services/VaccinationCentersService";
import MunicipalsService from "@/services/MunicipalsService";


export default {
    components: {
        errormessage
    },
    data() {
        return {
            errors: null,
            administrators_data:{},
            citizen: {
                name : '',
                national_number : '',
                family_paper_number : '',
                system_number : '',
                registration_number : '',
                brochure_number : '',
                date_of_birth : '',
                passport_number : '',
                phone_number : '',
                

                gender : '',
                relative_relation : '',

                citizen_number : '',

                helth_Center_name : '',
                health_Centerid : '',
                muncipal_name : '',
                municipal_id : '',
                nationalityid : '',



                guardian_number : '',
                
            },

            relative_relation: '',
            municipal_id: '',

            nationalities_data: {},
            municipals_data: {},
            vaccination_centers_data: {},
        };
    },

    watch: {
        municipal_id: function() {
            this.citizen.municipal_id = this.municipal_id
            this.getVaccinationCenters(this.municipal_id);

        },

        relative_relation: function() {
            
            if(this.relative_relation == 1){
                this.citizen.relative_relation = 1
                this.citizen.gender = 1
            }

            if(this.relative_relation == 2){
                this.citizen.relative_relation = 2
                this.citizen.gender = 2
            }

            if(this.relative_relation == 3){
                this.citizen.relative_relation = 3
                this.citizen.gender = 1
            }

            if(this.relative_relation == 4){
                this.citizen.relative_relation = 4
                this.citizen.gender = 2
            }

        },
    },
    
    created() {
        this.getNationalites();
        this.getMunicipals();

        this.municipal_id = this.$route.params.municipal_id

    },
    validations: {
        municipal_id: {
                required,
            },
        citizen: {
            name: {
                required,
                minLength: minLength(3),
            },
            passport_number: {
                required,
            },
            date_of_birth: {
                required,
            },
            municipal_id: {
                required,
            },
            health_Centerid: {
                required,
            },
            nationalityid: {
                required,
            },
            registration_number: {
                minLength: minLength(4),
            },
            guardian_number: {
                minLength: minLength(4),
            },
        },

        relative_relation: {
            required,
        },
    },
    methods: {

        getVaccinationCenters(id) {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            VaccinationCentersService.getVaccinationCentersByMunicipalWithoutPagination(id)
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.vaccination_centers_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        getMunicipals() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            MunicipalsService.getMunicipalsWithoutPagination()
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.municipals_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        getNationalites() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            NationalitiesService.getNationalitiesWithoutPagination()
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.nationalities_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },
       
        addMedicalSupply() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                name : this.citizen.name,
                national_number : this.citizen.national_number,
                nationalityid : this.citizen.nationalityid,
                citizen_number : this.citizen.citizen_number,
                gender : this.citizen.gender,
                family_paper_number : this.citizen.family_paper_number,
                system_number : this.citizen.system_number,
                registration_number : this.citizen.registration_number,
                brochure_number : this.citizen.brochure_number,
                relative_relation : this.citizen.relative_relation,
                date_of_birth : this.citizen.date_of_birth,
                passport_number : this.citizen.passport_number,
                helth_Center_name : this.citizen.helth_Center_name,
                muncipal_name : this.citizen.muncipal_name,
                health_Centerid : this.citizen.health_Centerid,
                municipal_id : this.citizen.municipal_id,
                phone_number : this.citizen.phone_number,
            }

            if ( this.citizen.nationalityid != '2378e80b-3358-427e-8544-9f776ea7e302' ) {
                if (this.citizen.relative_relation != 1 ) {
                    data.registration_number = this.citizen.guardian_number
                }else{
                    data.registration_number = this.citizen.passport_number
                }
                
            }

            CitizensService.createCitizen(data)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.$router.push({ name: "citizens_municipal" });
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.message,
                    })
                });
        },
    },
};
</script>

<style scoped>
.input.invalid label {
    color: red;
}
.input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
}

[type="submit"],
button {
    cursor: inherit;
    color: inherit;
}
</style>
