<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        إضافة لقاح

        {{ vaccine_id }}
        {{ vaccine_name }}
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <errormessage :error="errors"></errormessage>
      <form
        @submit.prevent="updateVaccine_for_test_i_wall_removed"
        class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden"
      >
        <div class="grid md:grid-cols-3 gap-6">
          <div class="col-span-3">
            <label for="vaccine_name_en" class="block text-sm font-medium">
              الفئة العمرية
            </label>
            <div
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <button
                @click="select_age_category(category.id)"
                type="button"
                class="ml-4 mb-4 px-4 py-2 hover:cursor-pointer"
                :class="
                  array_of_select_age_category.includes(category.id)
                    ? 'bg-blue-300'
                    : 'bg-gray-300'
                "
                v-for="category in age_categories_data"
                :key="category.id"
              >
                {{ category.description }}
              </button>
            </div>
          </div>
        </div>

        <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
          <div class="inline w-full md:w-auto cursor-pointer">
            <button
              type="submit"
              class="bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
            >
              إضافة
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import VaccinesService from "@/services/VaccinesService";
import AgeCategoriesService from "@/services/AgeCategoriesService";

export default {
  components: {
    errormessage,
  },
  data() {
    return {
      errors: null,

      vaccine: {
        name_ar: "",
        name_en: "",
        type: "",
      },

      vaccine_id: this.$route.params.id,
      vaccine_name: this.$route.params.name,

      age_categories_data: {},
      vaccine_type: 0,

      array_of_select_age_category: [],
      // loader: "dots",
    };
  },
  created() {
    this.getAgeCategoriess();
  },

  methods: {
    select_age_category(id) {
      if (this.array_of_select_age_category.includes(id)) {
        var index = this.array_of_select_age_category.indexOf(id);
        if (index !== -1) {
          this.array_of_select_age_category.splice(index, 1);
        }
      } else {
        this.array_of_select_age_category.push(id);
      }
    },

    getAgeCategoriess() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AgeCategoriesService.getAgeCategoriess(this.vaccine_type)
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.age_categories_data = res.data;
            // this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    updateVaccine_for_test_i_wall_removed() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        vaccine_id: this.$route.params.id,
        dosages: this.array_of_select_age_category,
      };

      VaccinesService.updateVaccine_for_test_i_wall_removed(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.$router.push({ name: "vaccines" });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },
  },
};
</script>

<style scoped>
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

[type="submit"],
button {
  cursor: inherit;
  color: inherit;
}
</style>
