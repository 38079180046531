<template>
    <div class="">
        <Bar    
            v-if="loaded"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
    </div>
</template>

<script>

import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
    components: { Bar },

    

    created() {
        setTimeout(() => {

            // console.log("this.ayoublabels")
            // console.log(this.labels)
            this.loaded = true
        }, 500);
    },

    props: {
        labels: {
            type: Array,
        },

        datasets: {
            type: Array,
        },

        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {

             loaded: false,

            chartData: {
                labels: this.labels,
                datasets: this.datasets,
            },

            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }

        }
    }
}
</script>

<style>

</style>