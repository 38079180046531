<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        إضافة مسؤول sssss
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <errormessage :error="errors"></errormessage>
      <form
        @submit.prevent="addAdministrator"
         autocomplete="off"
        class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden"
      >
        <div class="grid md:grid-cols-4 gap-8">
            <div
            class="input"
            :class="{ invalid: $v.administrator.full_name.$error }"
            >
            <label
                for="administrator_full_name"
                class="block text-sm font-medium"
            >
                الاسم
            </label>
            <input
                type="text"
                id="administrator_full_name"
                placeholder="أكتب الاسم."
                v-model="administrator.full_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.full_name.$touch()"
            />
            <p
                v-if="!$v.administrator.full_name.minLength"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي الاسم على
                {{ $v.administrator.full_name.$params.minLength.min }} أحرف على
                الأقل.
            </p>
            </div>

            <div
            class="input"
            :class="{ invalid: $v.administrator.usenamex.$error }"
            >
            <label
                for="administrator_usenamexx"
                class="block text-sm font-medium"
            >
                اسم المستخدم
            </label>
            <input
              autocomplete="off"
              
                type="text"
                id="administrator_usenamexx"
                placeholder="أكتب اسم المستخدم."
                v-model="administrator.usenamex"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.usenamex.$touch()"
            />
            <p
                v-if="!$v.administrator.usenamex.minLength"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي الاسم على
                {{ $v.administrator.usenamex.$params.minLength.min }} أحرف على
                الأقل.
            </p>
            </div>

            <div
            class="input"
            :class="{ invalid: $v.administrator.password.$error }"
            >
            <label
                for="administrator_password"
                class="block text-sm font-medium"
            >
                كلمة المرور
            </label>
            <input
                type="password"
                id="administrator_password"
                placeholder="********"
                autocomplete="off"
                v-model="administrator.password"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.password.$touch()"
            />
            <p
                v-if="!$v.administrator.password"
                class="text-xs text-red-500 mt-2"
            >
                يجب أن يحتوي الاسم على
                أحرف على
                الأقل.
            </p>
            </div>

            <div
            class="input"
            :class="{ invalid: $v.administrator.password_confirmation.$error }"
            >
            <label
                for="administrator_password_confirmation"
                class="block text-sm font-medium"
            >
                تاكيد كلمة المرور
            </label>
            <input
                type="password"
                id="administrator_password_confirmation"
                placeholder="********"
                v-model="administrator.password_confirmation"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.password_confirmation.$touch()"
            />
            <p
                v-if="!$v.administrator.password_confirmation.sameAsPassword"
                class="text-xs text-red-500 mt-2"
            >
                يجب ان تكون كلمة المرور متطابقة
            </p>
            </div>

            <div
                class="input"
                :class="{ invalid: $v.administrator.birth_date.$error }"
            >
                <label
                for="administrator_birth_date"
                class="block text-sm font-medium"
                >
                تاريخ الميلاد
                </label>
                <input
                type="date"
                id="administrator_birth_date"
                placeholder="أكتب تاريخ الميلاد."
                v-model="administrator.birth_date"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.birth_date.$touch()"
                />
            
            </div>

            <div
                class="input"
                :class="{ invalid: $v.administrator.phone_number.$error }"
            >
                <label
                for="administrator_phone_number"
                class="block text-sm font-medium"
                >
                رقم الهاتف
                </label>
                <input
                type="text"
                id="administrator_phone_number"
                placeholder="أكتب رقم الهاتف."
                v-model="administrator.phone_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @blur="$v.administrator.phone_number.$touch()"
                />
                <p
                v-if="!$v.administrator.phone_number.minLength"
                class="text-xs text-red-500 mt-2"
                >
                يجب أن يحتوي الاسم على
                {{ $v.administrator.phone_number.$params.minLength.min }} أحرف على
                الأقل.
                </p>
            </div>

            <div class="input col-span-4">
              <label for="permissions" class="block text-sm font-medium">
                الصلاحيات
              </label>
              <!-- flex items-center justify-start  -->
              <div
                id="roles"
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
              <div
                  v-for="role in roles"
                  :key="role.id"
                  class="w-auto m-1 rounded focus:outline-none"
              >
                  <div class="flex items-center border border-solid">
                    <button
                        type="button"
                        @click="addRole(role.id, role.name)"
                        class="py-1 px-4"
                        :disabled="
                        administrator.roles.id == role.id
                        "
                        :class="
                        administrator.roles.id == role.id
                        
                            ? 'w-8/12 cursor-not-allowed'
                            : 'w-full cursor-pointer'
                        "
                    >
                        {{ role.name }}
                    </button>

                    <button
                        type="button"
                        @click="removeRole(role.id)"
                        v-if="
                        administrator.roles.id == role.id
                        "
                        class="w-4/12 text-centers py-1 px-4 bg-red-500"
                    >
                        X
                    </button>
                  </div>

                  <div
                    class="grid grid-cols-4 gap-2 py-2"
                    v-if="administrator.roles.id == role.id"
                  >
                    <div
                        v-for="permission in administrator.roles.not_permissions"
                        :key="permission.id"
                        class="flex items-center border border-solid m-1"
                    >
                        <button
                          type="button"
                          @click="addPermission(role.id, permission.id)"
                          class="py-1 px-4 rounded focus:outline-none"
                          :disabled="toReturnspecificPermission( permission.id)"
                          :class="
                              toReturnspecificPermission( permission.id)
                              ? 'w-8/12 cursor-not-allowed'
                              : 'w-full cursor-pointer'
                          "
                        >
                          {{ permission.name }}
                        </button>

                        <button
                          type="button"
                          @click="removePermission(permission.id)"
                          class="w-4/12 text-centers py-1 px-4 bg-red-500"
                          v-if="toReturnspecificPermission( permission.id)"
                        >
                          X
                        </button>

                        <br /><br />
                    </div>
                  </div>
              </div>
              </div>
            </div>
        </div>

        <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
          <div
            class="inline w-full md:w-auto"
            :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <button
              :disabled="$v.$invalid"
              type="submit"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
              :class="
                $v.$invalid
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
              إضافة
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import { required, minLength, sameAs,  integer  } from "vuelidate/lib/validators";
import AdministratorsService from "@/services/AdministratorsService";
import MedicalSuppliesService from "@/services/MedicalSuppliesService";




export default {
  components: {
    errormessage,
  },
  data() {
    return {
      errors: null,

      MedicalSupplies_id : this.$route.params.MedicalSupplies_id,


      administrator: {
        usenamex: "",
        full_name: "",
        birth_date: "",
        phone_number: "",

        password: "",
        password_confirmation: "",

        roles: {},
      },

      roles: [],
      permissions: {},

      // loader: "dots",
    };
  },
  created() {

    this.administrator.usenamex = "",
    this.administrator.full_name = "",
    this.administrator.birth_date = "",
    this.administrator.phone_number = "",
    this.administrator.password = "",


    this.getRoles();
  },
  validations: {
    administrator: {
      full_name: {
        required,
        minLength: minLength(2),
      },
      usenamex: {
        required,
        minLength: minLength(2),
      },
      birth_date: {
        required,
      },
      phone_number: {
        minLength: minLength(10),
        integer
      },
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    removePermission(permissionId) {
 

      this.administrator.roles.permissions = this.administrator.roles.permissions.filter(
        (data) => data.primation_id != permissionId
      );
    },

    addPermission(roleId, permissionId) {

      this.administrator.roles.permissions.push({
        id: permissionId,
        role_id: roleId,
       
      });
    },

    toReturnspecificPermission(permissionId) {
      if ( this.administrator.roles.permissions.find((element) => element.id == permissionId) == undefined) 
      {
        return false;
      } else {
        return true;
      }
    },

    toReturnPermissions(x) {
      let role = this.administrator.roles.find((element) => element.id == x);
      return role.not_permissions;
    },

    removeRole(id) {
      this.administrator.roles = this.administrator.roles.filter(
        (data) => data.id != id
      );
    },

    addRole(id, name) {
      this.administrator.roles = {
        id: id,
        name: name,
        permissions: [],
        not_permissions: [],
      };

      this.getPermission(id);

    },

    getPermission(role_id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministratorsService.getPermissionById(role_id)
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            let xnxx = res.data;

            this.administrator.roles.not_permissions = xnxx
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    getRoles() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministratorsService.getRoles(this.page_num, this.page_size)
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.roles = res.data;
            this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    addAdministrator() {
        let loader = this.$loading.show({
            loader: this.loader,
        });
console.log(this.MedicalSupplies_id)
        let data = {
          id: this.MedicalSupplies_id,
          administrator : {
            usename: this.administrator.usenamex,
            full_name: this.administrator.full_name,
            password: this.administrator.password,
            password_confirmation: this.administrator.password_confirmation,
            birth_date: this.administrator.birth_date,
            phone_number: this.administrator.phone_number,
            role_name: this.administrator.roles.name,
          },
          permissions: this.administrator.roles.permissions,
        };

        loader.hide();

        MedicalSuppliesService.createAdministrator(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.$router.push({ name: "administrators", params: { id: this.$route.params.id } });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },
  },
};
</script>

<style scoped>
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

[type="submit"],
button {
  cursor: inherit;
  color: inherit;
}
</style>
