<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
                تعديل البيانات الشخصية 
            </h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form @submit.prevent="updateAdministrator" class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4  sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
                <div class="grid md:grid-cols-3 gap-8">
                    <div class="input" :class="{ invalid: $v.administrator.full_name.$error }">
                        <label for="administrator_full_name" class="block text-sm font-medium ">
                            اسم المسؤول
                        </label>
                        <input
                            type="text"
                            id="administrator_full_name"
                            placeholder="أكتب اسم المسؤول."
                            v-model="administrator.full_name"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            @blur="$v.administrator.full_name.$touch()"
                        />
                        <p v-if="!$v.administrator.full_name.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.administrator.full_name.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input">
                        <label for="administrator_birth_date" class="block text-sm font-medium ">
                            تاريخ الميلاد
                        </label>
                        <input
                            type="date"
                            id="administrator_birth_date"
                            v-model="administrator.birth_date"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div class="input">
                        <fieldset>
                            <legend for="administrator_name" class="block text-sm font-medium ">الجنس</legend>
                            <div class="mt-4 flex justify-between items-center">
                                <div class="flex items-center">
                                    <input id="male" name="gender" v-model="administrator.gender" value="male" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                    <label for="male" class="mr-3 block text-sm font-medium ">
                                        ذكر
                                    </label>
                                </div>
                                <div class="flex items-center">
                                    <input id="female" name="gender" v-model="administrator.gender" value="female" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                    <label for="female" class="mr-3 block text-sm font-medium ">
                                        أنتى
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div class="input" :class="{ invalid: $v.administrator.email.$error }">
                        <label for="administrator_email" class="block text-sm font-medium ">
                            البريد الإلكتروني
                        </label>
                        <input
                            type="email"
                            id="administrator_email"
                            placeholder="أكتب البريد الإلكتروني."
                            v-model="administrator.email"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            @blur="$v.administrator.email.$touch()"
                        />
                        <p v-if="!$v.administrator.email.email" class="text-xs text-red-500 mt-2">
                            يجب ان يحتوي هذا الحقل على عنوان بريد إلكتروني صالحًا.
                        </p>
                    </div>

                    <div class="input" :class="{ invalid: $v.administrator.phone_number.$error }">
                        <label for="administrator_phone_number" class="block text-sm font-medium ">
                            رقم الهاتف
                        </label>
                        <input
                            type="tel"
                            id="administrator_phone_number"
                            placeholder="2189xxxxxxxx"
                            v-model="administrator.phone_number"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            @blur="$v.administrator.phone_number.$touch()"
                        />
                        <p v-if="!$v.administrator.phone_number.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الرقم على 9 ارقام على الأقل. 
                        </p>
                        <p v-if="!$v.administrator.phone_number.integer" class="text-xs text-red-500 mt-2">
                            يجب ان يحتوي هذا الحقل علي أرقام فقط.
                        </p>
                    </div>

                    <div class="input" >
                        <label for="administrator_nid" class="block text-sm font-medium ">
                            الرقم الوطني
                        </label>
                        <input
                            type="text"
                            id="administrator_nid"
                            placeholder="أكتب الرقم الوطني."
                            v-model="administrator.nid"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        
                    </div>

                    

                  

                    <div class="input">
                        <label for="administrator_address" class="block text-sm font-medium ">
                            العنوان
                        </label>
                        <input
                            type="text"
                            id="administrator_address"
                            placeholder="أكتب العنوان."
                            v-model="administrator.address"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div v-if="administrator.roles == 'school_admin'" class="">
                        <label for="administrator_address" class="block text-sm font-medium ">
                            اختر المدرسة
                        </label>
                        <select
                            id="school"
                            name="school"
                            autocomplete="school-name"
                            v-model="schoolId"
                            class="mt-1 block w-full py-2 px-3 border border-solid border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option disabled selected value="">المدارس</option>
                            <option
                                v-for="school in schoolsData.data"
                                :key="school.id"
                                v-bind:value="school.id"
                            >
                                {{ school.name }}
                            </option>
                        </select>
                    </div>

                  
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$anyError ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            :disabled="$v.$anyError"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$anyError ? 'bg-gray-600' : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'">
                            تعديل
                        </button>
                    </div>
                </div>

                <!-- <ul v-for="(key, index) in administrator.privileges" :key="index" class="text-red-900 list-disc list-inside">
                    <li v-for="(item, index) in getPrivilegesKey(key)" :key="`${index}-privilege`">
                        {{ item }}
                    </li>
                </ul> -->

            </form>
        </div>
    </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage"
import { required, minLength, email, integer } from "vuelidate/lib/validators";
import AdministratorsService from "@/services/AdministratorsService";
// import SchoolService from "@services/SchoolService";

export default {
    created() {
        let loader = this.$loading.show({
            loader: this.loader,
        });

        setTimeout(() => {
            loader.hide();
        }, 200);
         
    },

    components: {
        errormessage
    },
    data() {
        return {
            // administrator: this.$store.state.auth.administrator,
            role: this.$store.state.auth.role,

            // this.$route.params.id

            schoolId : '',
            schoolsData: {},

            errors: null,
            administrator: {
                full_name : this.$store.state.auth.administrator.full_name,
                birth_date : this.$store.state.auth.administrator.birth_date,
                gender : this.$store.state.auth.administrator.gender,
                email : this.$store.state.auth.administrator.email,
                phone_number : this.$store.state.auth.administrator.phone_number,
                nid : this.$store.state.auth.administrator.nid,
                address : this.$store.state.auth.administrator.address,
               
                roles: this.$store.state.auth.role
            },
            // loader: "dots",
        };
    },
    validations: {
        administrator: {
            full_name: {
                required,
                minLength: minLength(2),
            },
            email: {
                required,
                email
            },
            birth_date: {
                required,
            },
            phone_number: {
                required,
                minLength: minLength(10),
                integer,
            },
        },
    },

    methods: {

        // getSchools(page) {
           

        //     SchoolService.getSchools(page)
        //         .then((resp) => {
                  

        //                 this.schoolsData = resp.data;
                  
        //         })
        //         .catch((err) => {
                   

        //             this.$swal.fire({
        //                 icon: "error",
        //                 title: "...عذرا",
        //                 text: err.response.data.errors.name,
        //             });
        //         });
        // },

        getPrivilegesKey(key) {
            return Object.keys(key);
        },
        
        updateAdministrator() {

            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                id: this.$route.params.id,
                name : this.administrator.name,
                birth_date : this.administrator.birth_date,
                gender : this.administrator.gender,
                email : this.administrator.email,
                phone_number : this.administrator.phone_number,
                nid : this.administrator.nid,
                address : this.administrator.address,
                
                roles : [this.administrator.roles],
            }

            if (this.schoolId != '') {
                this.linkAdminWithSchool()
            }

            AdministratorsService.updateAdministrator(data)
                .then((resp) => {

                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.$router.push({ name: "profile" });
                    }, 10);
                
                },
                (err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.errors.name,
                    })
                }
            );
        },

        linkAdminWithSchool() {
            let loader = this.$loading.show({
                loader: this.loader,
            });
            AdministratorsService.linkAdminWithSchool( this.$route.params.id,  this.schoolId)
            .then(
                (resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: "success",
                            text: resp.data.message,
                        });
                        
                    }, 10);
                },
                (err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.errors.name,
                    });
                }
            );
        },
    },
};
</script>

