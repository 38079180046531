import * as API from "./API";

import store from "../store";

export default {


    getRecoveryVaccine(page_number, Page_size, id) {
        return API.apiClient.get(`/api/Health_center_Storage/get_recovered_quantities_for_health_center?page_number=${page_number}&Page_size=${Page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getVaccinationsStorageWithoutPagination(id) {
        return API.apiClient.get(`/api/Health_center_Storage/GetAll?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinationsStorageWithoutRepeat(id) {
        return API.apiClient.get(`/api/Health_center_Storage/get_all_helthCenter_storges_without_repeat?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinationsStorage(page_number, page_size, id) {
        return API.apiClient.get(`/api/Health_center_Storage/GetAllHelthCenterStorges?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinationStorage(id) {
        return API.apiClient.get(`/api/Health_center_Storage/Health_center_Storage?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // createMedicalSupplyStorage(medicalsupplystorage) {
    //     return API.apiClient.post(`/api/Medical_supplies_Storage/Add`, medicalsupplystorage);
    // },

    // deleteMedicalSupplyStorage(id) {
    //     return API.apiClient.delete(`/api/Medical_supplies_Storage/Delete?id=${id}`);
    // },

    // updateMedicalSupply(medicalsupply) {
    //     return API.apiClient.put(`/api/Medical_supplies/Update`, medicalsupply);
    // },

};