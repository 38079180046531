<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        مراكز التطعيم
      </h1>

      <router-link
        title="إضافة"
        :to="{ name: 'addVaccinationCenter' }"
        class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        إضافة +
      </router-link>
    </div>
    




    <div class="mt-4 w-full relative">
      <div class="h-8 w-full flex items-center">
        <button
          @click="toggle_filter()"
          class="flex items-center hover:text-blue-600 cursor-pointer w-full"
        >
          <svg
            class="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
            />
          </svg>
          <span class="mr-2"> فرز </span>
        </button>

        <button
          v-if="in_search"
          @click="cancel_filter()"
          class="flex items-center justify-center w-28 md:w-48 text-center py-2 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <span class="ml-2"> إلغاء الفرز </span>

          <svg
            class="h-6 w-6 stroke-current"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.6974 6.69598L6.6974 18.696"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.6974 6.69598L18.6974 18.696"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

  

      <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-class="transform opacity-0 scale-y-0"
        enter-to-class="transform opacity-100 scale-y-100"
        leave-active-class="transition ease-in-out duration-75"
        leave-class="transform opacity-100 scale-y-100"
        leave-to-class="transform opacity-0 scale-y-0"
      >
        <div
          v-if="filter_box"
          class="absolute z-40 w-full top-8 sm:rounded-b-lg border-t-2 border-gray-700 dark:border-slate-400 bg-gray-300 dark:bg-slate-700 dark:bg-opacity-90 bg-opacity-90 px-4 pt-4 pb-8"
        >
          <div class="grid grid-cols-4 gap-8">
          

          
            <div class="input">
              <label
                for="filter_name"
                class="block text-sm font-medium"
              >
                اسم المركز
              </label>
              <input
                type="text"
                id="filter_name"
                placeholder="أكتب اسم المركز."
                v-model="filter_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

        

            <div class="input">
              <label for="municipal_id" class="block text-sm font-medium">
                اختر البلديات
              </label>
              <select
                id="municipal_id"
                name="municipal_id"
                v-model="filter_municipal_id"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option disabled selected>البلديات</option>
                <option
                  v-for="municipal in municipals_data"
                  :key="municipal.id"
                  v-bind:value="municipal.id"
                >
                  {{ municipal.name }}
                </option>
              </select>
            </div>

           

        

           
          </div>

          <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
            <div class="inline w-full md:w-auto cursor-pointer">
              <button
                @click="do_search()"
                type="button"
                class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                بحث
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>


    

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="w-1/12 px-6 py-3 hidden lg:block">
              الرقم
            </div>
            <div scope="col" class="w-7/12 md:w-4/12 px-6 py-3">الاسم</div>
            <div scope="col" class="md:w-2/12 px-6 py-3 hidden md:block">
              العنوان
            </div>

            <div scope="col" class="md:w-2/12 px-6 py-3 hidden md:block">
              البلدية
            </div>

            <!-- <div scope="col" class="md:w-2/12 px-6 py-3 hidden md:block">
              المسؤول
            </div> -->

            <div scope="col" class="w-5/12 md:w-3/12 px-6 py-3">الإجراءات</div>
          </div>



          <div
            v-if="total == 0"
            class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
          >
           

            <span > لايوجد مراكز تطعيم </span>
          </div>


          <div
            v-else
            v-for="(vaccination_center, personIdx) in vaccination_centers_data"
            :key="vaccination_center.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-50 dark:bg-slate-800'
            "
          >
            <div
              class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:flex items-center"
            >
              {{ personIdx + 1 }}

                <svg class="w-6 h-6 mr-2 fill-current " :class="vaccination_center.is_training ? 'text-green-500' : 'text-red-500' " id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 94.65">
                    <defs></defs>
                    <title>training</title>
                    <path class="cls-1" d="M21.92,0A10.36,10.36,0,1,1,11.57,10.35,10.35,10.35,0,0,1,21.92,0ZM14.61,22.7l3.68,9.65h.39l1.79-6.18-1-1.05c-.72-1.05-.47-2.25.87-2.47a9.91,9.91,0,0,1,1.45,0,8.37,8.37,0,0,1,1.59.06c1.24.28,1.37,1.48.75,2.44l-1,1.05L25,32.35h.38L28.7,22.7a4.52,4.52,0,0,0,.47.37,6.7,6.7,0,0,1,2.71.85,4.73,4.73,0,0,1,3,2l7.9,10.91,5.63,1.08V16.67h-8a1.86,1.86,0,1,1,0-3.72H77.56V7.52a1.87,1.87,0,0,1,3.73,0V13h39.53a1.86,1.86,0,1,1,0,3.72h-7.93V62.73H121a1.86,1.86,0,0,1,0,3.72H81.55V75a2.56,2.56,0,0,0,.26.2l11.62,11a1.85,1.85,0,1,1-2.54,2.68L81.55,80v8a1.87,1.87,0,0,1-3.73,0V79.68l-9.69,9.21a1.84,1.84,0,0,1-2.62-.07,1.82,1.82,0,0,1,.07-2.61l11.62-11h0a1.71,1.71,0,0,1,.62-.39V66.45H40.37a1.86,1.86,0,0,1,0-3.72h8V47.83L39.1,46A4.88,4.88,0,0,1,36,44.1l-3.93-5.45v.14l-.32,15.78,4,25.24,1.51,8.65c.85,5.78-8,9.21-10.19,1.62L21.91,59.66,16.66,90.57c-1,5.59-10.13,5.64-10.19-1.15l5.79-34.65-.44-18.71a7.9,7.9,0,0,0-1.19,2,12.61,12.61,0,0,0-1,4.58L8.83,53.55C8.39,59.25-.1,59.6,0,53.45c.05-2.76.3-5.54.6-8.37.58-5.42.7-8.78,3.79-14.18a18.3,18.3,0,0,1,4.5-5.21,12.14,12.14,0,0,1,4.88-2.32,7.26,7.26,0,0,0,.84-.67ZM52.13,38.26l10.46-11a2.47,2.47,0,1,1,3.58,3.4L56.33,41a4.89,4.89,0,0,1-4.2,7.46V62.73H109.2V16.67H52.13V38.26Z"/>
                </svg>
            </div>
            <div
              class="w-7/12 md:w-4/12 px-6 py-4 whitespace-nowrap text-sm truncate"
            >
              {{ vaccination_center.name }}
            </div>
            <div
              class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ vaccination_center.address }}
            </div>

            <div
              class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ vaccination_center.municipal_name }}
            </div>

            <!-- <div
              class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ vaccination_center.full_name }}
            </div> -->

            <div class="w-5/12 md:w-3/12 px-6 py-4">
              <div class="w-full flex items-center justify-between">

                <button @click="trining_is_done(vaccination_center.id)" v-if="vaccination_center.is_training == false" type="button" title="تم التدريب" class="border px-px">
                    T
                </button>
                <router-link
                  title="المخزن"
                  :to="{
                    name: 'vaccinesVaccinationCentersStorageByAdmin',
                    params: { id: vaccination_center.id },
                  }"
                  class="mx-2"
                >
                  <svg
                    class="h-6 w-6 stroke-2 stroke-current hover:text-blue-600"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line class="a" x1="2" x2="2" y1="6.225" y2="19.225" />
                    <line class="a" x1="22" x2="22" y1="6.225" y2="19.225" />
                    <path
                      class="a"
                      d="M5.5,16.225H12a0,0,0,0,1,0,0v7a0,0,0,0,1,0,0H5.5a.5.5,0,0,1-.5-.5v-6A.5.5,0,0,1,5.5,16.225Z"
                    />
                    <path
                      class="a"
                      d="M12,16.225h6.5a.5.5,0,0,1,.5.5v6a.5.5,0,0,1-.5.5H12a0,0,0,0,1,0,0v-7A0,0,0,0,1,12,16.225Z"
                    />
                    <path
                      class="a"
                      d="M8.5,9.225h6a.5.5,0,0,1,.5.5v6.5a0,0,0,0,1,0,0H8a0,0,0,0,1,0,0v-6.5A.5.5,0,0,1,8.5,9.225Z"
                    />
                    <path
                      class="a"
                      d="M10,9.225h3a0,0,0,0,1,0,0v2.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5v-2.5A0,0,0,0,1,10,9.225Z"
                    />
                    <path
                      class="a"
                      d="M7,16.225h3a0,0,0,0,1,0,0v2.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5v-2.5A0,0,0,0,1,7,16.225Z"
                    />
                    <path
                      class="a"
                      d="M14,16.225h3a0,0,0,0,1,0,0v2.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5v-2.5A0,0,0,0,1,14,16.225Z"
                    />
                    <path
                      class="a"
                      d="M22.5,6.225a.5.5,0,0,0,.5-.5V4.586a.5.5,0,0,0-.342-.475L12.3.822a1,1,0,0,0-.606,0L1.354,4.117A.5.5,0,0,0,1,4.6V5.725a.5.5,0,0,0,.5.5Z"
                    />
                  </svg>
                </router-link>

                <router-link
                  title="التألف"
                  :to="{
                    name: 'spoiledVaccinationCentersByAdmin',
                    params: { id: vaccination_center.id },
                  }"
                  class="mx-2"
                >
                  <svg
                    class="h-6 w-6 stroke-2 stroke-current hover:text-blue-600"
                    width="256"
                    height="256"
                    viewBox="0 0 256 256"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M96 72V48"
                      stroke-width="16"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M160 208V184"
                      stroke-width="16"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M72 96H48"
                      stroke-width="16"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M208 160H184"
                      stroke-width="16"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M71.0289 128.402L59.7151 139.716C52.2174 147.218 48.0062 157.391 48.0078 167.998C48.0094 178.605 52.2235 188.776 59.7235 196.276C67.2235 203.776 77.3953 207.99 88.0019 207.992C98.6085 207.994 108.781 203.782 116.284 196.285L127.597 184.971M184.97 127.598L196.284 116.285C203.781 108.782 207.993 98.6095 207.991 88.0028C207.989 77.3962 203.775 67.2245 196.275 59.7245C188.775 52.2245 178.604 48.0104 167.997 48.0088C157.39 48.0072 147.217 52.2183 139.715 59.7161L128.401 71.0298"
                      stroke-width="16"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </router-link>

                <button title="حظر" class="">
                  <svg
                    class="h-6 w-6 stroke-current hover:text-red-600"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.92993 4.93005L19.0699 19.0701"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <router-link
                  title="تعديل"
                  :to="{
                    name: 'editVaccinationCenter',
                    params: { id: vaccination_center.id },
                  }"
                  class="mx-2"
                >
                  <svg
                    class="h-6 w-6 stroke-current hover:text-blue-600"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </router-link>

                <button
                  title="حذف"
                  class=""
                  @click="deleteVaccinationCenter(vaccination_center.id)"
                >
                  <svg
                    class="h-6 w-6 stroke-current hover:text-red-600"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.80444 6.50317H5.80444H21.8044"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.80444 6.50317V4.50317C8.80444 3.97274 9.01516 3.46403 9.39023 3.08896C9.7653 2.71389 10.274 2.50317 10.8044 2.50317H14.8044C15.3349 2.50317 15.8436 2.71389 16.2187 3.08896C16.5937 3.46403 16.8044 3.97274 16.8044 4.50317V6.50317M19.8044 6.50317V20.5032C19.8044 21.0336 19.5937 21.5423 19.2187 21.9174C18.8436 22.2925 18.3349 22.5032 17.8044 22.5032H7.80444C7.27401 22.5032 6.7653 22.2925 6.39023 21.9174C6.01516 21.5423 5.80444 21.0336 5.80444 20.5032V6.50317H19.8044Z"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        dir="rtl"
        v-model="page_number"
        :per-page="page_size"
        :records="total"
        @paginate="getVaccinationCenters"
        class="z-10"
      />
    </div>
  </div>
</template>

<script>
import VaccinationCentersService from "@/services/VaccinationCentersService";
import MunicipalsService from "@/services/MunicipalsService";

export default {
  computed: {},
  data() {
    return {
      vaccination_centers_data: {},
      page_number: 1,
      page_size: 10,
      total: 0,

      municipals_data:{},
      filter_name:'',
      filter_municipal_id:'',

      filter_box: false,
      in_search: false,
    };
  },
  created() {
    this.getVaccinationCenters();
    this.getMunicipals();
  },
  methods: {


    cancel_filter() {
      this.in_search = false;

      this.filter_name = "";
      this.filter_municipal_id = "";

     

      this.page_number = 1;
      this.page_size = 10;
   

      this.getVaccinationCenters();
    },

    do_search() {
      this.page_number = 1;
      this.getVaccinationCenters();
      this.filter_box = !this.filter_box;
      this.in_search = true;
    },

    toggle_filter() {
      this.filter_box = !this.filter_box;
    },


    getMunicipals() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            MunicipalsService.getMunicipalsWithoutPagination()
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();
                        

                        this.municipals_data = res.data;
                       
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

    trining_is_done(id){

        let loader = this.$loading.show({
        loader: this.loader,
      });

      VaccinationCentersService.trining_is_done(id)
        .then(() => {
          setTimeout(() => {
            loader.hide();
            this.getVaccinationCenters();
            // this.vaccination_centers_data = res.data.list;
            // this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });


    },
    getVaccinationCenters() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      VaccinationCentersService.getVaccinationCentersSearch(
        this.page_number,
        this.page_size,
        this.filter_municipal_id,
        this.filter_name
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.vaccination_centers_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();


          this.vaccination_centers_data = {};
          this.total = 0;


          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: 'لاتوجد مراكز تطعيم',
          // });
        });
    },
    deleteVaccinationCenter(id) {
      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "!لن تتمكن من التراجع عن هذا",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، احذفها",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            VaccinationCentersService.deleteVaccinationCenter(id)
              .then((resp) => {
                let i = this.vaccination_centers_data
                  .map((item) => item.id)
                  .indexOf(id); // find index of your object
                this.vaccination_centers_data.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
