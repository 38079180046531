<template>
  <div class="">
    <div class="">
      <h1 class="text-2xl font-semibold text-slate-900 dark:text-white">احصائيات</h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div class="grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md"
        >
          <div class="p-4 text-right">
            <p class="block antialiased text-base leading-normal font-normal text-blue-gray-600">
              المسجلين
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
              {{ dashboard_data.total_of_citizen }}
            </h4>
          </div>

          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              class="w-6 h-6 text-white fill-current"
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 50 50"
              viewBox="0 0 50 50"
            >
              <path
                d="M24.3141,5.9217c-1.1045,0-2,0.8955-2,2v1.0835c0,3.0571-2.331,5.5801-5.3088,5.8854h-6.6319c-2.9783-0.3052-5.3093-2.8283-5.3093-5.8854V7.9217c0-2.6419-4-2.6449-4,0v1.0835c0,4.4979,3.0131,8.3001,7.1251,9.5096v11.2928c-0.0042,0.0524-0.0157,0.1027-0.0157,0.1561v16c0,1.1045,0.8955,2,2,2s2-0.8955,2-2V30.9219h2.9585v15.0419c0,1.1045,0.8955,2,2,2s2-0.8955,2-2V30.9219h0.0573v-12.407c4.1119-1.2095,7.1249-5.0116,7.1249-9.5096V7.9217C26.3141,6.8173,25.4186,5.9217,24.3141,5.9217z"
              />
              <path
                d="M13.6893 12.9219c2.2091 0 4-1.7909 4-4 0-2.2092-1.7909-4-4-4C8.4061 4.9219 8.3991 12.9219 13.6893 12.9219zM6.06 8.736v.27M4 17.366H2.26v-.5C1.8611 16.8992 1.3929 16.8715 1 16.736v4.72h6.19v-2.22C6.01 18.796 4.94 18.166 4 17.366zM47.52 17.366v-1.33h-1.18v-3.24H45.1v3.24h-1.17v-1.97h-2.51v1.97H40.3v-9.69h-.62v-2.81h-.96v-1.5h-2.7v1.5h-.96v2.81h-.61v11.02h-.92v-7.93H27.29c-.06 1.73-.54 3.39-1.37 4.85.22.16.36.43.36.75 0 .52-.4.94-.89.94-.18 0-.35-.05-.49-.15-1.22 1.51-2.83 2.71-4.71 3.41v2.22H49v-4.09H47.52zM28.2 15.976c-.51 0-.92-.42-.92-.94 0-.53.41-.94.92-.94.49 0 .89.41.89.94C29.09 15.556 28.69 15.976 28.2 15.976zM28.2 12.956c-.51 0-.92-.42-.92-.93 0-.54.41-.96.92-.96.49 0 .89.42.89.96C29.09 12.536 28.69 12.956 28.2 12.956zM30.98 15.976c-.5 0-.89-.42-.89-.94 0-.53.39-.94.89-.94s.92.41.92.94C31.9 15.556 31.48 15.976 30.98 15.976zM30.98 12.956c-.5 0-.89-.42-.89-.93 0-.54.39-.96.89-.96s.92.42.92.96C31.9 12.536 31.48 12.956 30.98 12.956zM36.34 16.576h-1.06v-8.12h1.06V16.576zM37.89 16.576h-1.03v-8.12h1.03V16.576zM39.46 16.576h-1.05v-8.12h1.05V16.576z"
              />
              <rect width="2.259" height="3.54" x="30.459" y="4.81" />
              <path d="M1.355,16.756" />
            </svg>
          </div>
        </div>

        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md"
        >
          <div class="p-4 text-right">
            <p class="block antialiased text-base leading-normal font-normal text-blue-gray-600">
              محصلة اللقاحات اليوم
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
              {{ dashboard_data.total_of_Obtained_Vaccinese_in_this_day }}
            </h4>
          </div>

          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              class="w-6 h-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </div>
        </div>

        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md"
        >
          <div class="p-4 text-right">
            <p class="block antialiased text-base leading-normal font-normal text-blue-gray-600">
              محصلة اللقاحات الشهر
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
              {{ dashboard_data.total_of_Obtained_Vaccinese_in_this_month }}
            </h4>
          </div>

          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              class="w-6 h-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </div>
        </div>

        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md"
        >
          <div class="p-4 text-right">
            <p class="block antialiased text-base leading-normal font-normal text-blue-gray-600">
              محصلة اللقاحات السنة
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
              {{ dashboard_data.total_of_Obtained_Vaccinese_this_in_year }}
            </h4>
          </div>

          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              class="w-6 h-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="mt-12">
        <div class="flex justify-between items-center">
          <p class="text-lg font-semibold text-slate-900 dark:text-white">أكثر البلديات سحب</p>

          <div class="" >
            <export-excel
              name="أكثر البلديات سحب"
              class="w-32 text-center hover:cursor-pointer py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              :data= "most_consumeds_municipals">
              سحب التقرير 
            </export-excel>
          </div>

          <p class="underline text-base font-semibold text-slate-900 dark:text-white">
            إجمالي المسجلين المتلقين للقاح في الفترة :

            {{ dashboard_consumed_muncipals_data_total_citizens }}
          </p>

          <p class="underline text-base font-semibold text-slate-900 dark:text-white">
            إجمالي اللقاحات في الفترة :

            {{ dashboard_consumed_muncipals_data_total }}
          </p>
        </div>

        <div class="mt-6">
          <div class="mt-2 flex justify-between items-center">
            <button
              :class="
                filter_period_most_consumed_muncipals == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_muncipals(2)
                open_custom_date_most_consumed_muncipals = false
              "
            >
              هذا اليوم
            </button>

            <button
              :class="
                filter_period_most_consumed_muncipals == 3
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_muncipals(3)
                open_custom_date_most_consumed_muncipals = false
              "
            >
              هذا الشهر
            </button>

            <button
              :class="
                filter_period_most_consumed_muncipals == 4
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_muncipals(4)
                open_custom_date_most_consumed_muncipals = false
              "
            >
              هذه السنة
            </button>

            <button
              :class="
                filter_period_most_consumed_muncipals == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_muncipals(1)
                open_custom_date_most_consumed_muncipals = false
              "
            >
              الكل
            </button>

            <button
              :class="
                filter_period_most_consumed_muncipals == 5
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_muncipals(5)
                open_custom_date_most_consumed_muncipals = true
              "
            >
              مخصص
            </button>
          </div>

          <div
            v-if="open_custom_date_most_consumed_muncipals"
            class="mt-2 flex justify-between items-center space-x-reverse space-x-6"
          >
            <div class="input w-1/3 flex items-center">
              <label
                for="filter_most_consumed_muncipals_date_from"
                class="w-12 text-sm font-medium"
              >
                من :
              </label>
              <input
                id="filter_most_consumed_muncipals_date_from"
                v-model="filter_most_consumed_muncipals_date_from"
                type="date"
                placeholder="أكتب تاريخ الميلاد."
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input w-1/3 flex items-center">
              <label for="filter_most_consumed_muncipals_date_to" class="w-12 text-sm font-medium">
                الي :
              </label>
              <input
                id="filter_most_consumed_muncipals_date_to"
                v-model="filter_most_consumed_muncipals_date_to"
                type="date"
                placeholder="أكتب تاريخ الميلاد."
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="w-1/3 cursor-pointer text-white">
              <button
                type="button"
                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                @click="period_fun_most_consumed_muncipals(5)"
              >
                بحث
              </button>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 mt-6 min-h-60 border-4 border-gray-400">
          <div
            v-for="(x, index) in most_consumeds_municipals"
            :key="index"
            class="border-t-4 border-l-4 border-r-4 border-b-4 border-gray-400 text-center h-full w-full relative"
          >
            <div class="flex justify-between items-start border-b-2">
              <div class="w-1/2 text-2xl border-l-2 px-1 py-6">
                <p class="text-base">المسجلين</p>
                {{ x.total_citizens }}
              </div>

              <div class="w-1/2 text-2xl border-r-2 px-1 py-6 relative group">
                <p class="text-base">الجرعات</p>

                {{ x.total_obtained_Vaccines }}

                <div
                  class="hidden group-hover:flex justify-center items-center absolute pt-4 z-20 inset-0 bg-green-300 bg-opacity-10"
                >
                  <router-link
                    target="_blank"
                    title="تقرير اللقاحات المفصل"
                    :to="{
                      name: 'printPeriodicStatisticsForMunicipalByAdministration',
                      params: {
                        period: filter_period_most_consumed_muncipals,
                        vaccine_type: vaccine_type_period_and_by_citizen_for_helth_center,
                        id: x.id,
                        from: filter_most_consumed_muncipals_date_from,
                        to: filter_most_consumed_muncipals_date_to,
                        municipal: x.name,
                        vaccinationCenter: x.name
                      }
                    }"
                    class="w-8 h-8 text-black hover:text-blue-700 focus:outline-none"
                  >
                    <svg
                      id="Capa_1"
                      class="fill-current stroke-2 w-8 h-8"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 192.287 192.287"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          d="M122.901,0H19.699v192.287h152.889v-142.6L122.901,0z M146.981,45.299h-19.686V25.612L146.981,45.299z M34.699,177.287V15
		h77.596v37.799c0,4.142,3.357,7.5,7.5,7.5h37.793v116.988H34.699z"
                        />
                        <rect x="53.141" y="149.004" width="86.006" height="10" />
                        <rect x="53.141" y="55.101" width="51.058" height="10" />
                        <polygon
                          points="121.248,86.935 126.79,86.935 105.371,108.353 88.623,91.605 51.597,128.634 58.667,135.706 88.623,105.748 
		105.371,122.495 133.861,94.005 133.861,99.535 143.861,99.535 143.861,76.935 121.248,76.935 	"
                        />
                        <rect x="53.141" y="33.283" width="51.058" height="10" />
                      </g>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="border-t-2 px-1 py-6">
              {{ x.name }}
            </div>
          </div>
        </div>

        <!-- <BarChart :labels="most_consumed_muncipals_labels" :datasets="most_consumed_muncipals_datasets"  /> -->
      </div>

      <div class="mt-12">
        <div class="flex justify-between items-center">
          <p class="text-lg font-semibold text-slate-900 dark:text-white">أكثر مراكز التطعيم سحب</p>

          <div class="" >
            <export-excel
              name="أكثر مراكز التطعيم سحب"
              class="w-32 text-center hover:cursor-pointer py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              :data= "most_consumeds_helth_center_with_municipals">
              سحب التقرير 
            </export-excel>
          </div>

          <p class="underline text-base font-semibold text-slate-900 dark:text-white">
            إجمالي المسجلين المتلقين للقاح في الفترة :

            {{ dashboard_consumed_helth_center_data_total_citizens }}
          </p>

          <p class="underline text-base font-semibold text-slate-900 dark:text-white">
            إجمالي اللقاحات في الفترة :

            {{ dashboard_consumed_helth_center_data_total }}
          </p>
        </div>

        <div class="mt-6">
          <p class="text-xl font-semibold text-slate-900 dark:text-white">الفترة</p>

          <div class="mt-2 flex justify-between items-center">
            <button
              :class="
                filter_period_most_consumed_helth_center == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_helth_center(2)
                open_custom_date_most_consumed_helth_center = false
              "
            >
              هذا اليوم
            </button>

            <button
              :class="
                filter_period_most_consumed_helth_center == 3
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_helth_center(3)
                open_custom_date_most_consumed_helth_center = false
              "
            >
              هذا الشهر
            </button>

            <button
              :class="
                filter_period_most_consumed_helth_center == 4
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_helth_center(4)
                open_custom_date_most_consumed_helth_center = false
              "
            >
              هذه السنة
            </button>

            <button
              :class="
                filter_period_most_consumed_helth_center == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_helth_center(1)
                open_custom_date_most_consumed_helth_center = false
              "
            >
              الكل
            </button>

            <button
              :class="
                filter_period_most_consumed_helth_center == 5
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_helth_center(5)
                open_custom_date_most_consumed_helth_center = true
              "
            >
              مخصص
            </button>
          </div>

          <div
            v-if="open_custom_date_most_consumed_helth_center"
            class="mt-2 flex justify-between items-center space-x-reverse space-x-6"
          >
            <div class="input w-1/3 flex items-center">
              <label
                for="filter_most_consumed_helth_center_date_from"
                class="w-12 text-sm font-medium"
              >
                من :
              </label>
              <input
                id="filter_most_consumed_helth_center_date_from"
                v-model="filter_most_consumed_helth_center_date_from"
                type="date"
                placeholder="أكتب تاريخ الميلاد."
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input w-1/3 flex items-center">
              <label
                for="filter_most_consumed_helth_center_date_to"
                class="w-12 text-sm font-medium"
              >
                الي :
              </label>
              <input
                id="filter_most_consumed_helth_center_date_to"
                v-model="filter_most_consumed_helth_center_date_to"
                type="date"
                placeholder="أكتب تاريخ الميلاد."
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="w-1/3 cursor-pointer text-white">
              <button
                type="button"
                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                @click="period_fun_most_consumed_helth_center(5)"
              >
                بحث
              </button>
            </div>
          </div>
        </div>

        <div class="mt-6">
          <p class="text-xl font-semibold text-slate-900 dark:text-white">تصنيف اللقاح</p>
          <div class="mt-2 flex justify-between items-center">
            <button
              :class="
                vaccine_type_period_and_by_citizen_for_helth_center == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="vaccine_type_period_and_by_citizen_for_helth_center_fun(1)"
            >
              الإجباري
            </button>

            <button
              :class="
                vaccine_type_period_and_by_citizen_for_helth_center == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="vaccine_type_period_and_by_citizen_for_helth_center_fun(2)"
            >
              كبار
            </button>

            <button
              :class="
                vaccine_type_period_and_by_citizen_for_helth_center == 3
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="vaccine_type_period_and_by_citizen_for_helth_center_fun(3)"
            >
              مسافرين
            </button>

            <button
              :class="
                vaccine_type_period_and_by_citizen_for_helth_center == 4
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="vaccine_type_period_and_by_citizen_for_helth_center_fun(4)"
            >
              حملات
            </button>
            <button
              :class="
                vaccine_type_period_and_by_citizen_for_helth_center == 0
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="vaccine_type_period_and_by_citizen_for_helth_center_fun(0)"
            >
              الكل
            </button>
          </div>
        </div>

        <div class="grid grid-cols-5 mt-6 min-h-60 border border-gray-400">
          <div
            v-for="(x, index) in most_consumeds_helth_center_with_municipals"
            :key="index"
            class="border-t-4 border-l-4 border-r-4 border-b-4 border-gray-400 text-center h-full w-full relative"
          >
            <div class="flex justify-between items-start">
              <div class="w-1/2 text-2xl border-r-2 px-1 py-6 relative group">
                <p class="text-base">المسجلين</p>
                {{ x.total_of_Citizens }}

                <div
                  class="hidden group-hover:flex absolute justify-center items-center pt-4 z-20 inset-0 bg-green-300 bg-opacity-10"
                >
                  <router-link
                    target="_blank"
                    title="تقرير اللقاحات المفصل"
                    :to="{
                      name: 'printCitizenVaccinationStatisticsByAdministration',
                      params: {
                        period: filter_period_most_consumed_helth_center,
                        vaccine_type: vaccine_type_period_and_by_citizen_for_helth_center,
                        id: x.health_center_id,
                        from: filter_most_consumed_helth_center_date_from,
                        to: filter_most_consumed_helth_center_date_to,
                        municipal: x.municipal,
                        vaccinationCenter: x.name
                      }
                    }"
                    class="w-8 h-8 text-black hover:text-blue-700 focus:outline-none"
                  >
                    <svg
                      id="Capa_1"
                      class="fill-current stroke-2 w-8 h-8"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 60 60"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M56.5,49L56.5,49V1c0-0.6-0.4-1-1-1h-45c-0.6,0-1,0.4-1,1v14h2V2h43v46h-9c-0.6,0-1,0.4-1,1v9h-33V43h-2v16
			c0,0.6,0.4,1,1,1h35c0.3,0,0.5-0.1,0.7-0.3l10-10c0.1-0.1,0.1-0.2,0.2-0.3v-0.1C56.5,49.2,56.5,49.1,56.5,49z M46.5,50h6.6
			l-3.3,3.3l-3.3,3.3L46.5,50L46.5,50z"
                          />
                          <path
                            d="M16.5,38h6h4v-2h-3V17c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v6h-5c-0.6,0-1,0.4-1,1v4h-5c-0.6,0-1,0.4-1,1v8
			c0,0.6,0.4,1,1,1h6H16.5z M17.5,18h4v18h-4V24V18z M11.5,25h4v11h-4v-7V25z M5.5,30h4v6h-4V30z"
                          />
                          <path
                            d="M50.5,24V7c0-0.6-0.4-1-1-1h-21c-0.6,0-1,0.4-1,1v17c0,0.6,0.4,1,1,1h21C50.1,25,50.5,24.6,50.5,24z M48.5,12h-12V8h12V12
			z M34.5,8v4h-5c0-1.6,0-4,0-4H34.5z M29.5,14h5v9h-5C29.5,23,29.5,18.3,29.5,14z M36.5,23v-9h12v9H36.5z"
                          />
                          <rect x="28.5" y="28" width="21" height="2" />
                          <rect x="28.5" y="33" width="21" height="2" />
                          <rect x="28.5" y="38" width="21" height="2" />
                          <rect x="14.5" y="6" width="6" height="2" />
                          <rect x="14.5" y="11" width="9" height="2" />
                          <rect x="14.5" y="43" width="7" height="2" />
                          <rect x="24.5" y="43" width="7" height="2" />
                          <rect x="34.5" y="43" width="7" height="2" />
                          <rect x="14.5" y="48" width="7" height="2" />
                          <rect x="24.5" y="48" width="7" height="2" />
                          <rect x="34.5" y="48" width="7" height="2" />
                          <rect x="14.5" y="53" width="7" height="2" />
                          <rect x="24.5" y="53" width="7" height="2" />
                          <rect x="34.5" y="53" width="7" height="2" />
                        </g>
                      </g>
                    </svg>
                  </router-link>
                </div>
              </div>

              <div class="w-1/2 text-2xl border-r-2 px-1 py-6 relative group">
                <p class="text-base">الجرعات</p>
                {{ x.total_obtained_Vaccines }}

                <div
                  class="hidden group-hover:flex absolute justify-center items-center pt-4 z-20 inset-0 bg-green-300 bg-opacity-10"
                >
                  <router-link
                    target="_blank"
                    title="تقرير اللقاحات"
                    :to="{
                      name: 'printPeriodicStatisticsByAdministration',
                      params: {
                        period: filter_period_most_consumed_helth_center,
                        vaccine_type: vaccine_type_period_and_by_citizen_for_helth_center,
                        id: x.health_center_id,
                        from: filter_most_consumed_helth_center_date_from,
                        to: filter_most_consumed_helth_center_date_to,
                        municipal: x.municipal,
                        vaccinationCenter: x.name
                      }
                    }"
                    class="w-8 h-8 text-black hover:text-blue-700 focus:outline-none"
                  >
                    <svg
                      id="Capa_1"
                      class="fill-current stroke-2 w-8 h-8"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 192.287 192.287"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          d="M122.901,0H19.699v192.287h152.889v-142.6L122.901,0z M146.981,45.299h-19.686V25.612L146.981,45.299z M34.699,177.287V15
		h77.596v37.799c0,4.142,3.357,7.5,7.5,7.5h37.793v116.988H34.699z"
                        />
                        <rect x="53.141" y="149.004" width="86.006" height="10" />
                        <rect x="53.141" y="55.101" width="51.058" height="10" />
                        <polygon
                          points="121.248,86.935 126.79,86.935 105.371,108.353 88.623,91.605 51.597,128.634 58.667,135.706 88.623,105.748 
		105.371,122.495 133.861,94.005 133.861,99.535 143.861,99.535 143.861,76.935 121.248,76.935 	"
                        />
                        <rect x="53.141" y="33.283" width="51.058" height="10" />
                      </g>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="border-t-2 px-1 py-6">
              {{ x.name }}
              <br />
              {{ x.municipal }}
            </div>
          </div>
        </div>

        <!-- <BarChart :labels="most_consumeds_helth_center_labels" :datasets="most_consumeds_helth_center_datasets"  /> -->
      </div>

      <div class="mt-20">
        <div class="flex justify-between items-center">
          <p class="text-lg font-semibold text-slate-900 dark:text-white">أكثر اللقاحات سحب</p>

          <div class="" >
            <export-excel
              name="أكثر اللقاحات سحب"
              class="w-32 text-center hover:cursor-pointer py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              :data= "most_consumed_vaccine_data">
              سحب التقرير 
            </export-excel>
          </div>


          <p class="underline text-base font-semibold text-slate-900 dark:text-white">
            إجمالي اللقاحات في الفترة :

            {{ most_consumed_vaccine_data_total }}
          </p>
        </div>

        <div class="mt-6">
          <div class="mt-2 flex justify-between items-center">
            <button
              :class="
                filter_period_most_consumed_vaccine == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_vaccine(2)
                open_custom_date_most_consumed_vacciner = false
              "
            >
              هذا اليوم
            </button>

            <button
              :class="
                filter_period_most_consumed_vaccine == 3
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_vaccine(3)
                open_custom_date_most_consumed_vacciner = false
              "
            >
              هذا الشهر
            </button>

            <button
              :class="
                filter_period_most_consumed_vaccine == 4
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_vaccine(4)
                open_custom_date_most_consumed_vacciner = false
              "
            >
              هذه السنة
            </button>

            <button
              :class="
                filter_period_most_consumed_vaccine == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_vaccine(1)
                open_custom_date_most_consumed_vacciner = false
              "
            >
              الكل
            </button>

            <button
              :class="
                filter_period_most_consumed_vaccine == 5
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
              @click="
                period_fun_most_consumed_vaccine(5)
                open_custom_date_most_consumed_vacciner = true
              "
            >
              مخصص
            </button>
          </div>

          <div
            v-if="open_custom_date_most_consumed_vacciner"
            class="mt-2 flex justify-between items-center space-x-reverse space-x-6"
          >
            <div class="input w-1/3 flex items-center">
              <label for="filter_most_consumed_vaccine_date_from" class="w-12 text-sm font-medium">
                من :
              </label>
              <input
                id="filter_most_consumed_vaccine_date_from"
                v-model="filter_most_consumed_vaccine_date_from"
                type="date"
                placeholder="أكتب تاريخ الميلاد."
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input w-1/3 flex items-center">
              <label for="filter_most_consumed_vaccine_date_to" class="w-12 text-sm font-medium">
                الي :
              </label>
              <input
                id="filter_most_consumed_vaccine_date_to"
                v-model="filter_most_consumed_vaccine_date_to"
                type="date"
                placeholder="أكتب تاريخ الميلاد."
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="w-1/3 cursor-pointer text-white">
              <button
                type="button"
                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                @click="period_fun_most_consumed_vaccine(5)"
              >
                بحث
              </button>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 mt-6 min-h-60 border border-gray-400">
          <div
            v-for="(x, index) in most_consumed_vaccine_data"
            :key="index"
            class="px-4 py-6 border-b border-l border-r border-t-0 border-gray-400 text-center h-full w-full"
          >
            <div class="text-2xl">
              {{ x.total_obtained_Vaccines }}
            </div>
            <div class="">
              {{ x.name_ar }}
            </div>
          </div>
        </div>

        <!-- <BarChart :labels="most_consumeds_Vaccine_labels" :datasets="most_consumeds_Vaccine_datasets"  /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from '@/services/DashboardService'
import Statistics from '@/services/StatisticsService'

export default {
  components: {},

  data() {
    return {
      xnxx: false,
      vaccine_type_period_and_by_citizen_for_helth_center: 0,

      openedMarkerID: null,

      dashboard_data: {},
      dashboard_consumed_muncipals_data: {},
      dashboard_consumed_helth_center_data: {},
      dashboard_consumed_vaccine_data: {},

      most_consumed_muncipals_labels: [],

      most_consumed_muncipals_datasets: [
        {
          color: '#FFF',
          label: ' ',
          backgroundColor: '#f87979',
          data: []
        }
      ],

      filter_most_consumed_muncipals_date_from: '',
      filter_most_consumed_muncipals_date_to: '',
      filter_period_most_consumed_muncipals: 2,
      open_custom_date_most_consumed_muncipals: false,

      most_consumeds_helth_center_labels: [],

      most_consumeds_helth_center_datasets: [
        {
          color: '#FFF',
          label: ' ',
          backgroundColor: '#3574ef',
          data: []
        }
      ],

      filter_most_consumed_helth_center_date_from: '',
      filter_most_consumed_helth_center_date_to: '',
      filter_period_most_consumed_helth_center: 2,
      open_custom_date_most_consumed_helth_center: false,

      most_consumeds_Vaccine_labels: [],

      most_consumeds_Vaccine_datasets: [
        {
          color: '#FFF',
          label: ' ',
          backgroundColor: '#49cc90',
          data: []
        }
      ],

      filter_most_consumed_vaccine_date_from: '',
      filter_most_consumed_vaccine_date_to: '',
      filter_period_most_consumed_vaccine: 2,
      open_custom_date_most_consumed_vacciner: false,

      data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20],

      most_consumeds_helth_center_with_municipals: {},
      most_consumeds_municipals: {},
      most_consumed_vaccine_data: {},

      dashboard_consumed_muncipals_data_total: 0,
      dashboard_consumed_muncipals_data_total_citizens: 0,

      dashboard_consumed_helth_center_data_total: 0,
      dashboard_consumed_helth_center_data_total_citizens: 0,
      most_consumed_vaccine_data_total: 0,

      vaccine_type: 1
    }
  },

  created() {
    this.getDashboardStatisticsNumber()

    var date = new Date()

    var month = date.getMonth() + 1
    var day = date.getDate()

    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day

    this.filter_most_consumed_muncipals_date_from = date.getFullYear() + '-' + month + '-' + day
    this.filter_most_consumed_muncipals_date_to = date.getFullYear() + '-' + month + '-' + day

    this.filter_most_consumed_helth_center_date_from = date.getFullYear() + '-' + month + '-' + day
    this.filter_most_consumed_helth_center_date_to = date.getFullYear() + '-' + month + '-' + day

    this.filter_most_consumed_vaccine_date_from = date.getFullYear() + '-' + month + '-' + day
    this.filter_most_consumed_vaccine_date_to = date.getFullYear() + '-' + month + '-' + day
  },
  methods: {
    vaccine_type_period_and_by_citizen_for_helth_center_fun(r) {
      this.vaccine_type_period_and_by_citizen_for_helth_center = r
      this.getDashboardMostConsumedHealthCenter()
    },

    period_fun_most_consumed_muncipals(p) {
      this.filter_period_most_consumed_muncipals = p
      this.getDashboardMostConsumedMuncipal()
    },

    period_fun_most_consumed_helth_center(p) {
      this.filter_period_most_consumed_helth_center = p
      this.getDashboardMostConsumedHealthCenter()
    },

    period_fun_most_consumed_vaccine(p) {
      this.filter_period_most_consumed_vaccine = p
      this.getDashboardMostConsumedVaccine()
    },

    getDashboardStatisticsNumber() {
      this.getDashboardMostConsumedMuncipal()
      this.getDashboardMostConsumedHealthCenter()
      this.getDashboardMostConsumedVaccine()

      // let loader = this.$loading.show({
      //   loader: this.loader,
      // });

      // Dashboard.getDashboardStatisticsNumberForAdministrationOfVaccinations()
      //   .then((res) => {
      //     setTimeout(() => {
      //       loader.hide();

      //       this.dashboard_data = res.data;

      //       this.getDashboardMostConsumedMuncipal();
      //       this.getDashboardMostConsumedHealthCenter();
      //       this.getDashboardMostConsumedVaccine();
      //     }, 10);
      //   })
      //   .catch((err) => {
      //     loader.hide();

      //     this.$swal.fire({
      //       icon: "error",
      //       title: "...عذرا",
      //       text: err.response.data.message,
      //     });
      //   });
    },

    getDashboardMostConsumedMuncipal() {
      this.dashboard_consumed_muncipals_data = {}
      this.dashboard_consumed_muncipals_data_total = 0
      this.dashboard_consumed_muncipals_data_total_citizens = 0

      this.most_consumeds_municipals = {}

      if (this.filter_period_most_consumed_muncipals != 5) {
        var date = new Date()

        var month = date.getMonth() + 1
        var day = date.getDate()

        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day

        this.filter_most_consumed_muncipals_date_from = date.getFullYear() + '-' + month + '-' + day
        this.filter_most_consumed_muncipals_date_to = date.getFullYear() + '-' + month + '-' + day
      }
      Dashboard.getDashboardMostConsumedMuncipalForAdministrationOfVaccinations(
        this.filter_period_most_consumed_muncipals,
        this.filter_most_consumed_muncipals_date_from,
        this.filter_most_consumed_muncipals_date_to,
        true
      )
        .then((res) => {
          setTimeout(() => {
            this.dashboard_consumed_muncipals_data = res.data

            this.dashboard_consumed_muncipals_data_total_citizens =
              this.dashboard_consumed_muncipals_data.total_citizens

            this.dashboard_consumed_muncipals_data_total =
              this.dashboard_consumed_muncipals_data.total
            this.most_consumeds_municipals =
              this.dashboard_consumed_muncipals_data.list.most_consumed_muncipals

            for (let index = 0; index < this.most_consumeds_municipals.length; index++) {
              const element = this.most_consumeds_municipals[index]

              this.most_consumed_muncipals_labels.push(element.name)
              this.most_consumed_muncipals_datasets[0].data.push(element.total_obtained_Vaccines)
            }

            //   }

            // this.total = this.dashboard_data.total;
          }, 10)
        })
        .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: '...عذرا',
            text: err.response.data.message
          })
        })
    },

    getDashboardMostConsumedHealthCenter() {
      this.dashboard_consumed_helth_center_data = {}
      this.dashboard_consumed_helth_center_data_total = 0
      this.dashboard_consumed_helth_center_data_total_citizens = 0

      this.most_consumeds_helth_center_with_municipals = {}

      if (this.filter_period_most_consumed_helth_center != 5) {
        var date = new Date()

        var month = date.getMonth() + 1
        var day = date.getDate()

        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day

        this.filter_most_consumed_helth_center_date_from =
          date.getFullYear() + '-' + month + '-' + day
        this.filter_most_consumed_helth_center_date_to =
          date.getFullYear() + '-' + month + '-' + day
      }
      Statistics.getDashboardMostConsumedHealthCenterForAdministrationOfVaccinations(
        this.filter_period_most_consumed_helth_center,
        this.filter_most_consumed_helth_center_date_from,
        this.filter_most_consumed_helth_center_date_to,
        true,
        this.vaccine_type_period_and_by_citizen_for_helth_center
      )
        .then((res) => {
          setTimeout(() => {
            this.dashboard_consumed_helth_center_data = res.data

            this.dashboard_consumed_helth_center_data_total_citizens =
              this.dashboard_consumed_helth_center_data.total_citizens

            this.dashboard_consumed_helth_center_data_total =
              this.dashboard_consumed_helth_center_data.total

            this.most_consumeds_helth_center_with_municipals =
              this.dashboard_consumed_helth_center_data.list.most_consumeds_helth_center_With_municipals

            // for (
            //   let index = 0;
            //   index <
            //   this.dashboard_consumed_helth_center_data
            //     .most_consumeds_helth_center.length;
            //   index++
            // ) {
            //   const element =
            //     this.dashboard_consumed_helth_center_data
            //       .most_consumeds_helth_center[index];

            //   this.most_consumeds_helth_center_labels.push(element.name);
            //   this.most_consumeds_helth_center_datasets[0].data.push(
            //     element.total_obtained_Vaccines
            //   );
            // }
          }, 10)
        })
        .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: '...عذرا',
            text: err.response.data.message
          })
        })
    },

    getDashboardMostConsumedVaccine() {
      this.dashboard_consumed_vaccine_data = {}
      this.most_consumed_vaccine_data_total = 0
      this.most_consumed_vaccine_data = {}

      if (this.filter_period_most_consumed_vaccine != 5) {
        var date = new Date()

        var month = date.getMonth() + 1
        var day = date.getDate()

        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day

        this.filter_most_consumed_vaccine_date_from = date.getFullYear() + '-' + month + '-' + day
        this.filter_most_consumed_vaccine_date_to = date.getFullYear() + '-' + month + '-' + day
      }

      Dashboard.getDashboardMostConsumedVaccineForAdministrationOfVaccinations(
        this.filter_period_most_consumed_vaccine,
        this.filter_most_consumed_vaccine_date_from,
        this.filter_most_consumed_vaccine_date_to,
        true
      )
        .then((res) => {
          setTimeout(() => {
            this.dashboard_consumed_vaccine_data = res.data

            this.most_consumed_vaccine_data_total = this.dashboard_consumed_vaccine_data.total

            this.most_consumed_vaccine_data =
              this.dashboard_consumed_vaccine_data.list.most_consumeds_Vaccine

            for (let index = 0; index < this.most_consumed_vaccine_data.length; index++) {
              const element = this.most_consumed_vaccine_data[index]

              this.most_consumeds_Vaccine_labels.push(element.name_en)
              this.most_consumeds_Vaccine_datasets[0].data.push(element.total_obtained_Vaccines)
            }
          }, 10)
        })
        .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: '...عذرا',
            text: err.response.data.message
          })
        })
    }
  }
}
</script>

<style></style>
