import * as API from "./API";

import store from "../store";

export default {

    updateVaccine_for_test_i_wall_removed(vaccine) {
        return API.apiClient.put(`/api/Vaccine/UpdateVaccine`, vaccine, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },













    addVaccinesToStorgeVaccinationCenters() {
        return API.apiClient.post(`/api/Vaccine/Vaccine_distribution_health`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    addVaccinesToStorgeMunicipals() {
        return API.apiClient.post(`/api/Vaccine/Vaccine_distribution_muncipal`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    addVaccinesToStorgeMedicalSupplies() {
        return API.apiClient.post(`/api/Vaccine/Vaccine_distribution_Medical`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinesWithoutPagination() {
        return API.apiClient.get(`/api/Vaccine/GetAll`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccines(page_number, page_size) {
        return API.apiClient.get(`/api/Vaccine/GetAllVaccine?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccine(id) {
        return API.apiClient.get(`/api/Vaccine/GetByID?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createVaccine(vaccine) {
        return API.apiClient.post(`/api/Vaccine/Add`, vaccine, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteVaccine(id) {
        return API.apiClient.delete(`/api/Vaccine/Delete?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateVaccine(vaccine) {
        return API.apiClient.put(`/api/Vaccine/Update`, vaccine, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};