<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"> طلب امداد لقاح </h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form
                @submit.prevent="requestVaccineForMunicipality"
                class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4  sm:p-6 shadow border dark:border-slate-800  sm:rounded-md sm:overflow-hidden"
            >
                <div class="grid md:grid-cols-4 gap-8">

                    
                    <div class="input" >
                        <label for="vaccine_id" class="block text-sm font-medium ">
                            اختر القاح 
                        </label>
                        <select
                            id="vaccine_id"
                            name="vaccine_id"
                            v-model="vaccine_to_order.vaccine_id"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>القاح</option>
                            <option
                                v-for="vaccine in vaccines_data"
                                :key="vaccine.id"
                                v-bind:value="vaccine.id"
                            >
                                {{ vaccine.name_en }}
                            </option>

                        </select>
                    </div>


               
       
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            :disabled="$v.$invalid"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                            إضافة
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import errormessage from "@/components/ErrorMessage"
import { required } from "vuelidate/lib/validators";

import VaccinesService from "@/services/VaccinesService";

import MunicipalsOrdersService from "@/services/MunicipalsOrdersService";





export default {
    components: {
        errormessage
    },
    data() {
        return {
            errors: null,
            
            vaccines_data:{},
            vaccine_to_order: {
               
                vaccine_id : '',

                order_state: 1,
            },

            municipal_id: this.$store.state.auth.role.guid,
        };
    },


    
    created() {
        this.getVaccines();
    },
    validations: {
        vaccine_to_order: {
            vaccine_id: {
                required,
            },
        },
    },
    methods: {

       

        getVaccines() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            VaccinesService.getVaccinesWithoutPagination()
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.vaccines_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },



        

       
        requestVaccineForMunicipality() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                order_state : 1,
                vaccine_id: this.vaccine_to_order.vaccine_id,
                municipal_id: this.municipal_id
            }

            MunicipalsOrdersService.requestVaccineForMunicipality(data)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.$router.push({ name: "requestvaccinesMunicipalsOrder", params: { id: this.$route.params.id }  });
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.errors.name,
                    })
                });
        },
    },
};
</script>

<style scoped>
.input.invalid label {
    color: red;
}
.input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
}

[type="submit"],
button {
    cursor: inherit;
    color: inherit;
}
</style>
