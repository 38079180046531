<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
                الطلبات
            </h1>

        </div>

        <div class="mt-4 flex items-center justify-between w-full relative">  
            <div class="h-8">
                <button @click="toggle_filter()" class="flex items-center hover:text-blue-600 cursor-pointer w-32">
                    <svg class="h-6 w-6 fill-current " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>
                    </svg>
                    <span class="mr-2">
                        فرز
                    </span>
                </button>
            </div>

            <div class="hidden xl:flex xl:ml-0 h-8">
                <label for="search-field" class="sr-only">Search</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <input
                        id="search-field"
                        class="block h-full bg-gray-50 dark:bg-slate-900 w-full border-transparent py-2 pr-8 dark:text-gray-400 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="بحث...."
                        type="search"
                        name="search"
                        v-model="filter_search"
                    />
                </div>
            </div>

            <transition
                enter-active-class="transition ease-in-out duration-200"
                enter-class="transform opacity-0 scale-y-0"
                enter-to-class="transform opacity-100 scale-y-100"
                leave-active-class="transition ease-in-out duration-75"
                leave-class="transform opacity-100 scale-y-100"
                leave-to-class="transform opacity-0 scale-y-0"
            >

                <div v-if="filter_box" class="absolute z-40 w-full top-8 sm:rounded-b-lg border-t-2 border-gray-700 dark:border-slate-400 bg-gray-300 dark:bg-slate-700 dark:bg-opacity-90 bg-opacity-90 px-4 pt-4 pb-8">

                    <div class="grid grid-cols-4 gap-8">
                        <div class="">
                            <label for="medical_supply_id" class="block text-sm font-medium ">
                                    موافقة ادارة التطعيمات
                            </label>
                            <select
                                id="is_approved"
                                name="is_approved"
                                v-model="filter_is_approved"
                                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option  disabled selected>الحالات</option>

                                <option v-bind:value="0">
                                    الكل
                                </option>

                                <option v-bind:value="1">
                                    تمت الموافقة
                                </option>

                                <option v-bind:value="2">
                                    تم الرفض
                                </option>

                                <option v-bind:value="3">
                                    قيد المعالجة
                                </option>

                            </select>
                        </div>
                    </div>

                </div>

            </transition>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <div class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right">
                        <div scope="col" class="w-1/12 px-6 py-3 hidden lg:block">
                            الرقم
                        </div>
                        <div scope="col" class="w-7/12 md:w-3/12 px-6 py-3">
                            اسم الصنف
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            الكمية
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            الحالة
                        </div>
                        
                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            البلدية
                        </div>

                        

                        <div scope="col" class="w-5/12 md:w-2/12 px-6 py-3">
                            الإجراءات
                        </div>
                    </div>
                    <div v-for="(order, personIdx) in order_data" :key="order.id"
                        class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                        :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                    >
                        <div class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                            {{ personIdx+1 }}
                        </div>

                        <div class="w-7/12 md:w-3/12 px-6 py-4 whitespace-nowrap text-sm truncate">
                            {{ order.vaccine_name }} 
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ order.amount }}
                        </div>

                        

                        <div class="w-3/12 md:w-2/12 px-2 py-4 whitespace-nowrap  text-sm truncate hidden md:block">
                            <span class=" font-bold py-1 px-2 text-white rounded-full" :class="order.order_state | medical_supplies_order_state_style">
                                {{ order.order_state | medical_supplies_order_state }}
                            </span>
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ order.municipal_name }} 
                        </div>

                        <div class="w-5/12 md:w-2/12 px-6 py-4">


                            <div class="w-16 ">
                                <!--  v-if="order.municipal_approval == null && order.order_state < 2 "  -->
                                <div class="flex justify-between">
                                    <button 
                                        title="صرف الطلب" class="" @click="update_order_approval_from_medical_supplier(order)">
                                        <svg class="w-6 h-6 stroke-current hover:text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7"></path></svg>
                                    </button>

                                    <button 
                                        title="تعذر الطلب" class="" @click="openOrderRejectModel(order.id)">
                                        <svg class="w-6 h-6 fill-current hover:text-red-600" fill="none" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>
                                    </button>
                                </div>
                            </div>

                            <!-- <div class="w-full flex items-center justify-between">
                                <button @click="update_order_approval_from_medical_supplier(order)" title="الموافقة على الطلب" class="">
                                    <svg class="w-6 h-6 stroke-current hover:text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7"></path></svg>
                                </button>
                            </div> -->
                        </div>
                    </div>
                </table>
            </div>
        </div>

        <div class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-gray-50 dark:bg-slate-800 relative">
            <pagination
                dir="rtl"
                v-model="page_number"
                :per-page="page_size"
                :records="total"
                @paginate="getcartForMedicalSupplier"
                class="z-10"
            />
        </div>

        <div v-if="order_reject_model" class="fixed z-40 bg-black bg-opacity-70 inset-0 ">
            <div class="min-h-screen flex justify-center items-center">
                <form class="w-1/3 px-4 text-slate-500 dark:text-slate-400 border-2 border-gray-600 bg-gray-50 dark:bg-slate-900 py-4" @submit.prevent="OrderReject()">
                
                    <div class=" flex justify-between items-center">
                        <div class="">
                            ادكر سبب رفض الطلب
                        </div>
                        <div class="">
                            <button @click="order_reject_model = false" class="hover:text-red-500 cursor-pointer">X</button>
                        </div>
                    </div>

                    <hr class="bg-gray-400 h-0.5 mt-4">

                    <div class="input mt-6 px-4" :class="{ invalid: $v.rejectOrder.reason.$error }">
                        <label for="rejectOrder_reason" class="block text-sm font-medium ">
                            السبب
                        </label>
                        <input
                            type="text"
                            id="rejectOrder_reason"
                            placeholder="أكتب السبب."
                            v-model="rejectOrder.reason"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 text-black bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.rejectOrder.reason.$touch()"
                        />
                    </div>

                    <div class="text-white md:w-auto mt-8  w-full">
                        <div class="w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                            <button
                                :disabled="$v.$invalid"
                                type="submit"
                                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 text-sm font-medium focus:outline-none"
                                :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                                حفظ
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import CartService from "@/services/CartService";
import { required } from "vuelidate/lib/validators";


export default {
    computed: {},
    data() {
        return {
            order_data: {},
            page_number: 1,
            page_size: 10,
            total: 0,

            filter_box: false,

            filter_search: '',
            filter_is_approved: '',

            order_state_to_get_oeder_approvel_or_delever: 1,

            order_reject_model: false,

            rejectOrder: {
                id:'',
                order_state: 77,
                reason:'',
            },
        };
    },

    watch: {
        // filter_search: function() {
        //     this.getCitizens()
        // },
        filter_is_approved: function() {
            if(this.filter_is_approved == 0){
                this.filter_is_approved = '';
            }
            this.getcartForMedicalSupplier()
        },
    },


     validations: {
        rejectOrder: {
            id: {
                required,
            },
            order_state: {
                required,
            },
            reason: {
                required,
            },
        },
    },


    created() {
        this.getcartForMedicalSupplier();
    },
    methods: {

         openOrderRejectModel(order_id){
            this.rejectOrder.id = order_id;
            this.order_reject_model = true;
        },

        OrderReject(){
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                id : this.rejectOrder.id,
                order_state : this.rejectOrder.order_state,
                reason : this.rejectOrder.reason,
            }

            CartService.OrderReject(data)
                .then((resp) => {
                    this.order_reject_model = false;
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })

                        this.rejectOrder.id = '';
                        this.rejectOrder.reason = '';
                        
                        this.getMedicalSupplies();
                        
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.rejectOrder.id = '';
                    this.rejectOrder.reason = '';

                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.message,
                    })
                });
        },


        update_order_approval_from_medical_supplier(order) {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            CartService.update_order_approval_from_medical_supplier(order)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.getcartForMedicalSupplier();
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.message,
                    })
                });
        },


        toggle_filter(){
            this.filter_box = !this.filter_box;
        },
   
        getcartForMedicalSupplier() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            CartService.getcartForMedicalSupplierByID(
                this.page_number,
                this.page_size,
                this.$store.state.auth.role.guid,
                this.order_state_to_get_oeder_approvel_or_delever,
            )
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();
                        this.order_data = res.data.list;
                        this.total = res.data.total;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

       
    },
};
</script>


<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: .5rem;
        margin-right: .5rem;
}


.VuePagination__pagination-item {
     background-color: transparent;  
}

 .page-item {
     background-color: transparent;  
}
.page-link {
    background-color: transparent;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  /* background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); */

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
