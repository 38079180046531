import * as API from "./API";

import store from "../store";

export default {
    // getMuncipalOrderWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },

    getcart(page_number, page_size) {
        return API.apiClient.get(`/api/Order/get_group_Carts?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getcartForMedicalSupplier(page_number, page_size) {
        return API.apiClient.get(`/api/Order/Order_NCDC_Medical_supplies?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getcartForMedicalSupplierByID(page_number, page_size, id, state) {
        return API.apiClient.get(`/api/Order/Order_NCDC_Medical_supplies_by_Id?page_number=${page_number}&page_size=${page_size}&id=${id}&state=${state}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // getMuncipalOrderById(id) {
    //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // },

    createOrderToMedicalSupplier(order) {
        return API.apiClient.post(`/api/Order/addOrder_NCDC_Medical_supplies`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    orderApprovalFromMedicalSupplier(order) {
        return API.apiClient.put(`/api/Order/order_approval_from_medical_supplier`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    update_order_approval_from_medical_supplier(order) {
        return API.apiClient.put(`/api/Order/update_order_approval_from_medical_supplier`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    OrderReject(order) {
        return API.apiClient.get(`/api/Order/reject_order_from_mdical_supplies`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },




    confirmDelivery(id) {
        return API.apiClient.get(`/api/Order/confirm_delivery?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },






    // updateMuncipalOrder(order) {
    //     return API.apiClient.put(`/api/Order`, order);
    // },

};