<template>
  <div
    class="modal bg-gray-500 bg-opacity-70 fixed top-0 left-0 h-screen w-full flex justify-center items-center z-50"
    :class="
      showModal
        ? 'modalVisible pointer-events-auto opacity-100'
        : 'pointer-events-none opacity-0 '
    "
  >
    <form
      ref="modal"
      class="modal-wrap w-80rem max-h-formHeight bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 p-8 shadow border dark:border-slate-800 sm:rounded-md screen:overflow-y-auto"
      :class="showModal ? 'modal-wrapVisable' : ''"
    >
      <div class="grid md:grid-cols-2 gap-8 mt-4">
        <div
          class="input"
          :class="{ invalid: $v.vaccineDeliveryDocument.recipient.$error }"
        >
          <label
            for="vaccineDeliveryDocument_recipient"
            class="block text-sm font-medium"
          >
            الجهة المستلمة
          </label>
          <input
            id="vaccineDeliveryDocument_recipient"
            v-model="vaccineDeliveryDocument.recipient"
            type="text"
            placeholder="أكتب الجهة المستلمة ."
            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            @blur="$v.vaccineDeliveryDocument.recipient.$touch()"
          >
        </div>

        <div
          class="input"
          :class="{
            invalid: $v.vaccineDeliveryDocument.representativeName.$error,
          }"
        >
          <label
            for="vaccineDeliveryDocument_representativeName"
            class="block text-sm font-medium"
          >
            إسم المندوب
          </label>
          <input
            id="vaccineDeliveryDocument_representativeName"
            v-model="vaccineDeliveryDocument.representativeName"
            type="text"
            placeholder="أكتب إسم المندوب ."
            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            @blur="$v.vaccineDeliveryDocument.representativeName.$touch()"
          >
        </div>

        <div
          class="input"
          :class="{ invalid: $v.vaccineDeliveryDocument.category.$error }"
        >
          <label
            for="vaccineDeliveryDocument_category"
            class="block text-sm font-medium"
          >
            الصنف
          </label>
          <input
            id="vaccineDeliveryDocument_category"
            v-model="vaccineDeliveryDocument.category"
            type="text"
            placeholder="أكتب الصنف ."
            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            @blur="$v.vaccineDeliveryDocument.category.$touch()"
          >
        </div>

        <div
          class="input"
          :class="{ invalid: $v.vaccineDeliveryDocument.giverName.$error }"
        >
          <label
            for="vaccineDeliveryDocument_giverName"
            class="block text-sm font-medium"
          >
            إسم المسلم
          </label>
          <input
            id="vaccineDeliveryDocument_giverName"
            v-model="vaccineDeliveryDocument.giverName"
            type="text"
            placeholder="أكتب إسم المسلم ."
            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            @blur="$v.vaccineDeliveryDocument.giverName.$touch()"
          >
        </div>
      </div>

      <p
        v-if="storeEmpty"
        class="mt-8"
      >
        الكمية غير موجودة في المخزن
      </p>

      <div v-if="!storeEmpty">
        <div class="grid grid-cols-2 md:grid-cols-deliveryModal gap-4 mt-8">
          <p>اللقاح</p>
          <p class="hidden lg:block">
            رقم التشغيلة
          </p>
          <p class="hidden lg:block">
            تاريخ الإنتاج
          </p>
          <p class="hidden lg:block">
            الكمية
          </p>
          <p>الكمية المعطى</p>
        </div>

        <div
          v-for="(vaccin, index) in vaccineInformation"
          :key="vaccin.id"
          class="mt-3 grid grid-cols-2 md:grid-cols-deliveryModal gap-4 items-baseline"
        >
          <p
            class="bg-gray-100 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.vaccine_name }}
          </p>

          <p
            class="hidden lg:block bg-gray-100 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.batch_number }}
          </p>

          <p
            class="hidden lg:block bg-gray-100 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.production_date }}
          </p>

          <p
            class="hidden lg:block bg-gray-100 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.quantity }}
          </p>

          <div>
            <div class="flex items-center gap-4">
              <input
                id="vaccineDeliveryDocument_giverName"
                v-model="inputValues[index]"
                type="number"
                placeholder="أكتب الكمية المعطى ."
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @keydown="restrictNegativeNumbers"
                @blur="$v.vaccineDeliveryDocument.giverName.$touch()"
                @input="checkIfLessThanQuantity($event, vaccin.quantity, index)"
                @wheel="disableSpinner"
              >
            </div>

            <p
              v-if="inputsErrors[index]"
              class="mt-2 text-red-600 text-sm"
            >
              لا يمكن إدخال اكثر من {{ vaccin.quantity }}
            </p>
          </div>
        </div>
      </div>

      <div class="text-white w-full md:w-auto flex md:justify-end gap-4 mt-8">
        <div class="inline w-full md:w-auto">
          <button
            class="font-medium leading-4 w-44 h-10 transition-all duration-200 ease-in rounded tracking-widest btn btn-cancel cursor-pointer"
            type="button"
            @click="cancelModal"
          >
            إلغاء
          </button>
        </div>
        <div
          class="inline w-full md:w-auto"
          :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
        >
          <button
            :disabled="$v.$invalid || !inputsValid"
            class="font-medium leading-4 w-44 h-10 transition-all duration-200 ease-in rounded tracking-widest btn"
            :class="
              $v.$invalid || !inputsValid
                ? 'bg-gray-600'
                : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            "
            type="button"
            @click="blockQuantityFromMedicalSuppliesStoreges"
          >
            تجهيز
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import MedicalSuppliesOrderService from "@/services/MedicalSuppliesOrdersService";
import store from "../../../../store";

export default {
  props: {
    order: {
      default: null,
      type: Object,
    },
    showModal: {
      default: null,
      type: Boolean,
    },
  },

  data() {
    return {
      vaccineOrder: {
        id: "",
        recipient: "",
        category: "",
      },

      vaccineDeliveryDocument: {
        recipient: "",
        representativeName: "",
        category: "",
        giverName: "",
      },

      vaccineInformation: [],

      inputValues: [],
      inputsErrors: [],
      inputsValid: false,
      storeEmpty: false,

      firstTime: true,
    };
  },

  watch: {
    order: {
      handler(order) {
        if (order) {
          this.inputValues = []
          this.inputsErrors = []
          this.vaccineDeliveryDocument.recipient = order.municipal_Name;
          this.vaccineDeliveryDocument.category = order.vaccine_Name_En;
          this.vaccineDeliveryDocument.giverName =
            store.state.auth.administrator.full_name;

          MedicalSuppliesOrderService.cheekMedicalSuppliesStorages(
            order.vaccine_id
          )
            .then((response) => {
              this.vaccineInformation = response.data;
            })
            .catch((err) => {
              if (err.response.data.message) {
                this.storeEmpty = true;
              }
            });
        }
      },
    },

    showModal: {
      handler(showModal) {
        if (showModal) {
          this.firstTime = true;
          window.addEventListener("click", this.closeDialogOutside);
        }
      },
    },

    inputValues: {
      deep: true,
      handler(newValues) {
        this.inputsValid =
          newValues.some((item) => item !== "") &&
          this.inputsErrors.every((item) => item === false);
      },
      immediate: true,
    },
  },

  validations: {
    vaccineDeliveryDocument: {
      recipient: {
        required,
      },
      representativeName: {
        required,
      },
      category: {
        required,
      },
      giverName: {
        required,
      },
    },
  },

  methods: {
    blockQuantityFromMedicalSuppliesStoreges() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      const body = {
        recipient_name: this.vaccineDeliveryDocument.representativeName,
        order_state: 7,
        vaccine: this.vaccineInformation
          .map((vaccin, index) => {
            if (this.inputValues[index] !== "" && this.inputValues[index]) {
              return {
                medical_Supplies_Storages_id: vaccin.id,
                quantity: Number(this.inputValues[index]),
              };
            } else return null;
          })
          .filter((value) => value !== null),
      };

      MedicalSuppliesOrderService.blockQuantityFromMedicalSuppliesStoreges(
        body,
        this.order.id
      )
        .then((response) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: response.data.message,
            });
          }, 10);
          this.$emit("cancel-modal", this.order.id);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    checkIfLessThanQuantity(event, quantity, index) {
      this.inputsErrors[index] = event.target.value > quantity 
      this.inputValues[index] = Math.abs(event.target.value)
    },

    restrictNegativeNumbers(event) {
      const keyCode = event.keyCode || event.which;
      const restrictedKeys = [69, 109, 189, 107];
      if (restrictedKeys.includes(keyCode)) {
        event.preventDefault();
      }
    },

    disableSpinner(event) {
      event.preventDefault();
    },

    closeDialogOutside(event) {
      if (this.firstTime) {
        this.firstTime = false;
        return;
      }

      if (!this.$refs.modal.contains(event.target)) {
        this.cancelModal();
      }
    },

    cancelModal() {
      this.$emit("cancel-modal");
      window.removeEventListener("click", this.closeDialogOutside);
    },
  },
};
</script>

<style>
.modal {
  background-color: rgba(31, 32, 41, 0.75);
  transition: opacity 250ms 200ms ease;
}

.modalVisible {
  transition: all 300ms ease-in-out;
}

.modal-wrap {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 100ms ease, transform 300ms 100ms ease;
}

.modal-wrapVisable {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 100ms ease, transform 350ms 100ms ease;
}

.btn {
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
}

.btn-cancel {
  background-color: #0b1846;
  border: 2px solid #0b1846;
  color: #ffeba7;
}

.btn-cancel:hover {
  background-color: transparent;
  color: #0b1846;
}

.close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 110;
  color: #ffeba7;
  cursor: pointer;
  box-shadow: 0 12px 25px 0 rgba(16, 39, 112, 0.25);
  transition: all 200ms linear;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
}

.close:hover {
  transform: translateY(-4px);
}

.closeAfter {
  transition: opacity 300ms 300ms ease, transform 300ms 300ms ease,
    background-color 250ms linear, color 250ms linear;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
</style>
