import * as API from "./API";

import store from "../store";

export default {


    getDashboardMostConsumedHealthCenterForAdministrationOfVaccinations(falg, from, to, is_all, vaccine_type) {
        return API.apiClient.get(`/api/Report/Ncdc_most_health_center_consumed_report_with_municipals?flag=${falg}&from=${from}&to=${to}&is_all=${is_all}&vaccine_type=${vaccine_type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getDashboardMostConsumedHealthCenterForMuncipal(id, falg, from, to) {
        return API.apiClient.get(`/api/Report/most_spoiled_muncipal_detailes?id=${id}&flag=${falg}&from=${from}&to=${to}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },






    // getDashboardForAdministrationOfVaccinations() {
    //     return API.apiClient.get(`/api/Dashboard/get_ncdc_dashboard`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },


    // getDashboardStatisticsNumberForAdministrationOfVaccinations() {
    //     return API.apiClient.get(`/api/Dashboard/Ncdc_dashboard_statistics_number`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },

    // getDashboardLocationsForAdministrationOfVaccinations() {
    //     return API.apiClient.get(`/api/Dashboard/Ncdc_dashboard_location`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },







    // getDashboardMostConsumedVaccineForAdministrationOfVaccinations(falg, from, to) {
    //     return API.apiClient.get(`/api/Dashboard/Ncdc_dashboard_most_consumeds_Vaccine?flag=${falg}&from=${from}&to=${to}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },









    // // getMuncipalOrderWithoutPagination(is_approved, id) {
    // //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // // },

    // getLocations() {
    //     return API.apiClient.get(`/api/HealthCenter/Location`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },


    // getDashboardForMuncipal(id) {
    //     return API.apiClient.get(`/api/Dashboard/get_muncipal_dashboard?id=${id}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },






    // getDashboardForVaccinationCenters(id) {
    //     return API.apiClient.get(`/api/Dashboard/get_health_center_dashboard?id=${id}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },

    // // getMuncipalOrderById(id) {
    // //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // // },

    // createOrder(order) {
    //     return API.apiClient.post(`/api/Order`, order, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },

    // addToCary(order) {
    //     return API.apiClient.post(`/api/Order/cart`, order, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },

    // updateToCary(order) {
    //     return API.apiClient.put(`/api/Order/update_order`, order, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },

    // orderApproval(id) {
    //     return API.apiClient.put(`/api/Order/change_state_of_order_from_center?id=${id}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },

    // OrderReject(order) {
    //     return API.apiClient.put(`/api/Order/reject_order`, order, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },



    // getOrderWithMedicalSupply(page_number, page_size) {
    //     return API.apiClient.get(`/api/Order/all_orders?page_number=${page_number}&page_size=${page_size}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${store.state.auth.accessToken}`
    //                 // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
    //         }
    //     });
    // },



    // updateMuncipalOrder(order) {
    //     return API.apiClient.put(`/api/Order`, order);
    // },

};