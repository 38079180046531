<template>
  <div class="">
    <div class="">
      <h1 class="text-2xl font-semibold text-slate-900 dark:text-white">
        لوحة التحكم
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">

      
      
    </div>
  </div>
</template>

<script>
// import Dashboard from "@/services/DashboardService";


export default {
  components: {
  },

  data() {
    return {
      openedMarkerID: null,
      center: { lat: 51.093048, lng: 6.84212 },
      markers_location_of_health_center: [],
      markers_location_of_muncipal: [],
      markers_location_medical_supplies: [],

      selected_location_health_center: null,
      selected_location_muncipal: null,
      selected_location_medical_supplies: null,

      info_box_open_health_center: false,
      info_box_open_muncipal: false,
      info_box_open_medical_supplies: false,

      dashboard_data: {},


      most_consumed_muncipals_labels: [],

      most_consumed_muncipals_datasets: [
        
          {
            color: '#FFF',
              label: ' ',
              backgroundColor: '#f87979',
              data: []
          },
          
      ],


      most_consumeds_helth_center_labels: [],

      most_consumeds_helth_center_datasets: [
        
          {
            color: '#FFF',
              label: ' ',
              backgroundColor: '#3574ef',
              data: []
          },
          
      ],

      most_consumeds_Vaccine_labels: [],

      most_consumeds_Vaccine_datasets: [
        
          {
            color: '#FFF',
              label: ' ',
              backgroundColor: '#49cc90',
              data: []
          },
          
      ],


      
      data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20],
    };
  },

  created() {
    // this.getDashboard();
  },
  methods: {
    // openInfoWindow(location, plase) {
    //   switch (plase) {
    //     case 1:
    //       this.selected_location_health_center = location;
    //       this.info_box_open_health_center = true;
    //       break;

    //     case 2:
    //       this.selected_location_muncipal = location;
    //       this.info_box_open_muncipal = true;
    //       break;

    //     case 3:
    //       this.selected_location_medical_supplies = location;
    //       this.info_box_open_medical_supplies = true;
    //       break;

    //     // default:
    //     //     break;
    //   }
    // },

    // closeInfoWindow(plase) {
    //   switch (plase) {
    //     case 1:
    //       this.info_box_open_health_center = false;
    //       break;

    //     case 2:
    //       this.info_box_open_muncipal = false;
    //       break;

    //     case 3:
    //       this.info_box_open_medical_supplies = false;
    //       break;

    //     // default:
    //     //     break;
    //   }
    // },

    // getDashboard() {
    //   let loader = this.$loading.show({
    //     loader: this.loader,
    //   });

    //   console.log("this.dashboard_data.most_consumed_muncipals.length")

    //   Dashboard.getDashboardForAdministrationOfVaccinations()
    //     .then((res) => {
    //       setTimeout(() => {
    //         loader.hide();
         
            
    //         this.dashboard_data = res.data;

    //         this.markers_location_of_health_center =
    //           this.dashboard_data.location_of_health_center;
    //         this.markers_location_of_muncipal = this.dashboard_data.location_of_muncipal;
    //         this.markers_location_medical_supplies =
    //           this.dashboard_data.location_medical_supplies;

           


    //           for (let index = 0; index < this.dashboard_data.most_consumed_muncipals.length; index++) {
    //             const element = this.dashboard_data.most_consumed_muncipals[index];

    //             this.most_consumed_muncipals_labels.push(element.name)  
    //             this.most_consumed_muncipals_datasets[0].data.push(element.total_obtained_Vaccines) 
    //           }

    //           for (let index = 0; index < this.dashboard_data.most_consumeds_helth_center.length; index++) {
    //             const element = this.dashboard_data.most_consumeds_helth_center[index];

    //             this.most_consumeds_helth_center_labels.push(element.name)  
    //             this.most_consumeds_helth_center_datasets[0].data.push(element.total_obtained_Vaccines) 
    //           }

    //           for (let index = 0; index < this.dashboard_data.most_consumeds_Vaccine.length; index++) {
    //             const element = this.dashboard_data.most_consumeds_Vaccine[index];

    //             this.most_consumeds_Vaccine_labels.push(element.name_en)  
    //             this.most_consumeds_Vaccine_datasets[0].data.push(element.total_obtained_Vaccines) 
    //           }


              

    //         // this.total = this.dashboard_data.total;
    //       }, 10);
    //     })
    //     .catch((err) => {
    //       loader.hide();

    //       this.$swal.fire({
    //         icon: "error",
    //         title: "...عذرا",
    //         text: err.response.data.message,
    //       });
    //     });
    // },
  },
};
</script>

<style></style>
