<template>
  <div class="mt-16 xl:mt-0">
      <div class="flex justify-between items-center">
          <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
              اللقاحات المسترجعة
          </h1>
      </div>

  

      <div class="py-2 align-middle inline-block min-w-full mt-6">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                  <div class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right">
                     
                      <div scope="col" class="w-7/12 md:w-3/12 px-6 py-3">
                          اللقاح
                      </div>

                      <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                          مركز التطغيم 
                      </div>
                      
                      <div scope="col" class="w-3/12 md:w-5/12 px-6 py-3 hidden md:block">
                          سبب الاسترجاع
                      </div>

                      <div scope="col" class="w-3/12 md:w-1/12 px-6 py-3 hidden md:block">
                          الكمية
                      </div>

                      <div scope="col" class="w-3/12 md:w-1/12 px-2 py-3 hidden md:block">
                          تاريخ
                      </div>

                    
                  </div>
                  <div v-for="(vaccine, personIdx) in spoiled_data" :key="vaccine.id"
                      class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                      :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                  >   
                    

                      <div class="w-3/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                          {{ vaccine.vaccine_name_en }} 
                      </div>

                      <div class="w-2/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                          {{ vaccine.health_center_name }}
                      </div>

                      <div class="w-5/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                          {{ vaccine.reason }}
                      </div>

                      <div class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                          {{ vaccine.quantity }}
                      </div>

                      <div class="w-1/12 px-2 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                          {{ vaccine.insert_at }}
                      </div>

                  
                    
                  </div>
              </table>
          </div>
      </div>

      <div class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-gray-50 dark:bg-slate-800 relative">
          <pagination
              dir="rtl"
              v-model="page_number"
              :per-page="page_size"
              :records="total"
              @paginate="getRecoveryVaccine"
              class="z-10"
          />
      </div>


  </div>
</template>

<script>
import MunicipalsStorageService from "@/services/MunicipalsStorageService";


export default {
  computed: {},
  data() {
      return {
          spoiled_data: {},
          page_number: 1,
          page_size: 10,
          total: 0,

          vaccination_center: this.$route.params.id,

      };
  },





  created() {
      this.getRecoveryVaccine();
  },
  methods: {
      getRecoveryVaccine() {
          let loader = this.$loading.show({
              loader: this.loader,
          });

          MunicipalsStorageService.getRecoveryVaccine(
              this.page_number,
              this.page_size,
              this.$route.params.id,
          )
              .then((res) => {
                  setTimeout(() => {
                      loader.hide();
                      this.spoiled_data = res.data.list;
                      this.total = res.data.total;
                  }, 10);
              })
              .catch((err) => {
                  loader.hide();

                  this.$swal.fire({
                      icon: "error",
                      title: "...عذرا",
                      text: err.response.data.message,
                  });
              });
      },

     
  },
};
</script>


<style>
.VuePagination__count {
display: none;
}

.VuePagination {
width: 100%;
}

.VuePagination nav {
display: flex;
justify-content: space-between;
align-items: center;
}

.pagination {
display: flex;
}

.page-link {
background-color: red;
}

.page-item {
margin-left: .5rem;
      margin-right: .5rem;
}


.VuePagination__pagination-item {
   background-color: transparent;  
}

.page-item {
   background-color: transparent;  
}
.page-link {
  background-color: transparent;
padding-left: 0.5rem;
padding-right: 0.5rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;

font-size: 0.75rem;
/* line-height: 1.25rem; */

font-weight: 500;
border-width: 1px;

--tw-border-opacity: 0;
border-color: rgba(209, 213, 219, var(--tw-border-opacity));

--tw-bg-opacity: 1;
/* background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); */

--tw-text-opacity: 1;
color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
--tw-bg-opacity: 1;
background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
--tw-text-opacity: 1;
color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
background-color: rgba(16, 185, 129);
color: #fff;
}

.VuePagination nav ul {
padding-top: 0.3rem;
padding-bottom: 0.5rem;
border-radius: 0.375rem;
overflow: hidden;
}
</style>
