<template>
  <div class=" flex justify-center itmes-center flex-col  bg-gray-300">
    <div class="w-96 mx-auto bg-gray-300 py-16">
        <input class="w-full" v-model="text_to_encrypt" placeholder="Enter a string" /> <br />
        <div class="mt-8">
            <button class="w-24 bg-red-400 mx-4" @click="encryptData()">Encrypt</button>
        </div>

        <div class="mt-8 bg-gray-100 w-full">
            <p class="">{{text_encrypted}}</p>
        </div>
    </div>



    <div class="w-96 mx-auto bg-gray-300 py-16">
        <input class="w-full" v-model="text_to_decrypt" placeholder="Enter a text_to_decrypt" /> <br />
        <div class="mt-8">
            <button class="w-24 bg-red-400 mx-4" @click="decryptData()">Decrypt</button>
        </div>

        <div class="mt-8 bg-gray-100 w-full">
            <p class="">{{text_decrypted}}</p>
        </div>
    </div>

  </div>
</template>

<script>
import CryptoJS from 'crypto-js'

export default {
  name: "App",
  data() {
    return {
      text_to_encrypt: "",
      secret: "aqwedrtgyujiolpj",
      text_encrypted: "",
      text_to_decrypt : "",
      text_decrypted: "",

    };
  },
  methods: {
    encryptData() {
        this.text_encrypted = CryptoJS.AES.encrypt(this.text_to_encrypt, this.secret).toString();
    },

    // U2FsdGVkX1+I9zqR5m3yc82XbUvbCDG4RruJEblv7/A=


    // E1QX/byDgZkf+QrKX7ep7g==
    // 7o+fWrMDNm3KKFRTc+vRvg==

    decryptData() {
        this.text_decrypted = CryptoJS.AES.decrypt( this.text_to_decrypt, this.secret ).toString(CryptoJS.enc.base64);
        // this.text_decrypted = CryptoJS.AES.decrypt( this.text_to_decrypt, this.secret ).toString();
        // 
    },

  },
};
</script>