import * as API from "./API";

import store from "../store";

export default {


    getInternalTransferRequests(id, page_number, page_size) {
        return API.apiClient.get(`/api/citizen_transfer/Citizens_internal_requests?id=${id}&page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    RequestRejectFromInternalTransferRequest(id) {
        return API.apiClient.put(`/api/citizen_transfer/reject?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    RequestApprovalFromInternalTransferRequest(id) {
        return API.apiClient.put(`/api/citizen_transfer/internal_approval?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getImportTransferRequests(id, page_number, page_size) {
        return API.apiClient.get(`/api/citizen_transfer/Citizens_import_requests?id=${id}&page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },




    RequestApprovalFromMunicipalToSendRequestToOther(id) {
        return API.apiClient.put(`/api/citizen_transfer/Approval_from_the_municipality_to_which_the_transfer_is_to_be_made?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },




    getExportTransferRequests(id, page_number, page_size) {
        return API.apiClient.get(`/api/citizen_transfer/Citizens_export_requests?id=${id}&page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },




    RequestApprovalFromCurrentMunicipal(id) {
        return API.apiClient.put(`/api/citizen_transfer/Approval_from_the_current_municipality?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },








};