import Vue from "vue";


Vue.filter("gender_state", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "ذكر";
    else if (status === 2) statusName = "انثى";
    else status = "-";
    return statusName;
});


Vue.filter("vaccination_center_order_state", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "انتظار";
    else if (status === 2) statusName = "تمت موافقة البلدية";
    else if (status === 3) statusName = "تم تحديد الكمية";
    else if (status === 4) statusName = "في انتظار استلامك";
    else if (status === 22) statusName = "البلدية رفضت";
    else if (status === 23) statusName = "الادارة رفضت";
    else if (status === 24) statusName = "الادارة رفضت";
    else if (status === 5) statusName = "موجود في مخازنك";
    else if (status === 6) statusName = "البلدية رفضت";
    else if (status === 7) statusName = "تم موافقة الامداد";
    else if (status === 8) statusName = "في الطريق للبلدية";
    else if (status === 9) statusName = "في مخازن البلدية";
    else if (status === 10) statusName = "في انتظار استلامك";
    else if (status === 11) statusName = "موجود في مخازنك";

    else status = "-";
    return statusName;
});



Vue.filter("vaccination_center_order_state_style", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "bg-yellow-300";
    else if (status === 2) statusName = "bg-green-200";
    else if (status === 3) statusName = "bg-yellow-700";
    else if (status === 4) statusName = "bg-purple-500";
    else if (status === 22) statusName = "bg-gray-700";
    else if (status === 23) statusName = "bg-gray-900";
    else if (status === 24) statusName = "bg-gray-900";
    else if (status === 5) statusName = "bg-purple-900";
    else if (status === 6) statusName = "bg-gray-700";
    else if (status === 7) statusName = "bg-blue-500";
    else if (status === 8) statusName = "bg-blue-700";
    else if (status === 9) statusName = "bg-purple-300";
    else if (status === 10) statusName = "bg-purple-500";
    else if (status === 11) statusName = "bg-purple-900";
    else status = "-";
    return statusName;
});


Vue.filter("municipal_order_state", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "طلب جديد";
    else if (status === 2) statusName = "تمت الموافقة";
    else if (status === 3) statusName = "تم تحديد الكمية";
    else if (status === 4) statusName = "تم التسليم للمركز";
    else if (status === 22) statusName = "تم الرفض";
    else if (status === 23) statusName = "الادارة رفضت";
    else if (status === 24) statusName = "الادارة رفضت بدونك";
    else if (status === 5) statusName = "في مخازن المركز";
    else if (status === 6) statusName = "تم الرفض";
    else if (status === 7) statusName = "تم موافقة الامداد";
    else if (status === 8) statusName = "في انتظار استلامك";
    else if (status === 9) statusName = "موجود في مخازنك";
    else if (status === 10) statusName = "تم التسليم للمركز";
    else if (status === 11) statusName = "في مخازن المركز";


    else status = "-";
    return statusName;
});



Vue.filter("municipal_order_state_style", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "bg-yellow-300";
    else if (status === 2) statusName = "bg-green-200";
    else if (status === 3) statusName = "bg-green-400";
    else if (status === 4) statusName = "bg-purple-500";
    else if (status === 22) statusName = "bg-gray-700";
    else if (status === 23) statusName = "bg-gray-900";
    else if (status === 24) statusName = "bg-gray-900";
    else if (status === 5) statusName = "bg-purple-900";
    else if (status === 6) statusName = "bg-blue-300";
    else if (status === 7) statusName = "bg-blue-500";
    else if (status === 8) statusName = "bg-blue-700";
    else if (status === 9) statusName = "bg-purple-300";
    else if (status === 10) statusName = "bg-purple-500";
    else if (status === 11) statusName = "bg-purple-900";
    else status = "-";
    return statusName;
});

















Vue.filter("administration_order_state", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "طلب جديد";
    else if (status === 2) statusName = "تمت موافقة البلدية";
    else if (status === 3) statusName = "تمت الموافقة";
    else if (status === 4) statusName = "تمت الموافقة بدون البلدية";
    else if (status === 22) statusName = "البلدية رفضت";
    else if (status === 23) statusName = "تم الرفض";
    else if (status === 24) statusName = " تم الرفض بدون البلدية";
    else if (status === 5) statusName = "تم تحديد الكمية";
    else if (status === 6) statusName = "تم الطلب";
    else if (status === 7) statusName = "تم موافقة الامداد";
    else if (status === 8) statusName = "في الطريق للبلدية";
    else if (status === 9) statusName = "في مخازن البلدية";
    else if (status === 10) statusName = "في الطريق للمركز";
    else if (status === 11) statusName = "في مخازن المركز";


    else status = "-";
    return statusName;
});



Vue.filter("administration_order_state_style", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "bg-yellow-300";
    else if (status === 2) statusName = "bg-green-300";
    else if (status === 3) statusName = "bg-green-500";
    else if (status === 4) statusName = "bg-green-400";
    else if (status === 22) statusName = "bg-gray-700";
    else if (status === 23) statusName = "bg-gray-900";
    else if (status === 24) statusName = "bg-gray-900";
    else if (status === 5) statusName = "bg-yellow-700";
    else if (status === 6) statusName = "bg-blue-300";
    else if (status === 7) statusName = "bg-blue-500";
    else if (status === 8) statusName = "bg-blue-700";
    else if (status === 9) statusName = "bg-purple-300";
    else if (status === 10) statusName = "bg-purple-500";
    else if (status === 11) statusName = "bg-purple-900";
    else status = "-";
    return statusName;
});







Vue.filter("medical_supplies_order_state", function(status) {
    if (status === null) return "-";
    let statusName = "";

    if (status === 6) statusName = "طلب جديد";
    else if (status === 7) statusName = "تم الموافقة";
    else if (status === 8) statusName = "تم التسليم للبلدية";
    else if (status === 9) statusName = "في مخازن البلدية";
    else if (status === 10) statusName = "في الطريق للمركز";
    else if (status === 11) statusName = "في مخازن المركز";

    else status = "-";
    return statusName;
});



Vue.filter("medical_supplies_order_state_style", function(status) {
    if (status === null) return "-";
    let statusName = "";

    if (status === 6) statusName = "bg-yellow-300";
    else if (status === 7) statusName = "bg-green-300";
    else if (status === 8) statusName = "bg-green-500";
    else if (status === 9) statusName = "bg-purple-300";
    else if (status === 10) statusName = "bg-purple-500";
    else if (status === 11) statusName = "bg-purple-900";
    else status = "-";
    return statusName;
});