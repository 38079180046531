<template>
  <div class="h-screen w-screen bg-gray-900  flex flex-col justify-center items-center">


    <p class="text-4xl text-gray-300">
        اه اوه! أعتقد أنك ضائع.
    </p>

    <p class="text-2xl mt-6 text-gray-500">
        يبدو أن الصفحة التي تبحث عنها غير موجودة.
    </p>

    <router-link to="/" class="mt-6 w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        ارجع الى البيت
    </router-link>

  </div>
</template>

<script>
export default {
    data() {
        return {
            name: "",
        };
    },
}
</script>

<style>


</style>